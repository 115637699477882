/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useState } from 'react';
import './EditorBottomLeftHeader.scss';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/carousel/style.scss';
import '@ingka/tabs/style.scss';
import '@ingka/focus/style.scss';
import SitsContext from '../../../../context/SitsContext';
import lablesAndnames from '../../../../lablesAndnames/labelsForComponents.json';

const EditorBottomLeftHeader = (props) => { // Tab change functionality
  const sitsContext = useContext(SitsContext);
  const [currentActiveTab, setCurrentActiveTab] = useState('tab_0');

  useEffect(() => {
    if (props.selectedSegment.tableRowClicked === true) {
      setCurrentActiveTab("tab_0");
    }
  }, [props.selectedSegment]);

  useEffect(() => {
    if (props.selectedSegment.confirmButtonClicked === true) {
      setCurrentActiveTab("tab_1");
    }
  }, [props.selectedSegment]);

  useEffect(() => {
    if (props.tabChangeOnNoResults === true || sitsContext.shortcutKeyForConcordance === true) {
      setCurrentActiveTab("tab_1");
      props.tabChangeHandler(false);
      sitsContext.updateshortcutKeyForConcordance(false);
    }
  }, [props.tabChangeOnNoResults, sitsContext.shortcutKeyForConcordance]);

  useEffect(() => {
    const headerElement = document.getElementById("editorBottomLeftHeaderSection");
    if (sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === true) {
      headerElement.style.width = `${sitsContext.editorBottomSectionHeight}px`;
    } else {
      headerElement.style.width = '100%';
    }
  }, [sitsContext.editorBottomSectionHeight, sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate]);

  const changetab = (e) => {
    setCurrentActiveTab(e);
    if (e === "tab_0") {
      sitsContext.updateConcordanceRetrieveData('tabChange', true);
      sitsContext.updateChangeMatchesDetails(true);
    }
    sessionStorage.setItem('selectedtab', e); // selectedtab is stored in sesssion storage , to retrive value when the page is reloaded
  };
  const pageRedirectHandler = (pageName) => {
    sitsContext.updateEditorBottomLeftHeader(pageName);
  };

  const tabs = [
    <Tab
      key="tab_0"
      onClick={() => pageRedirectHandler('matches')}
      // eslint-disable-next-line max-len
      // eslint-disable-next-line react/destructuring-assignment, react/prop-types, no-nested-ternary
      text={
        (props.selectedSegment.initialRendering === true
        || props.selectedSegment.confirmButtonClicked === true)
          ? lablesAndnames.tab_matches : `${lablesAndnames.tab_matches} (${sitsContext.matchesSuggestionsCount})`
      }
      tabPanelId="tab_0"
      disabled={props.selectedSegment.confirmButtonClicked === true}
    />,
    <Tab
      key="tab_1"
      onClick={() => pageRedirectHandler('concordance')}
      text={lablesAndnames.tab_concordance}
      tabPanelId="tab_1"
    />,
  ];
  const tabsRotated = [
    <Tab
      key="tab_1"
      onClick={() => pageRedirectHandler('concordance')}
      text={lablesAndnames.tab_concordance}
      tabPanelId="tab_1"
    />,
    <Tab
      key="tab_0"
      onClick={() => pageRedirectHandler('matches')}
      // eslint-disable-next-line max-len
      // eslint-disable-next-line react/destructuring-assignment, react/prop-types, no-nested-ternary
      text={props.selectedSegment.confirmButtonClicked === true ? lablesAndnames.tab_matches : sitsContext.matchesSuggestionsCount === undefined ? `${lablesAndnames.tab_matches}(0)` : `${lablesAndnames.tab_matches}(${sitsContext.matchesSuggestionsCount})`}
      tabPanelId="tab_0"
      disabled={props.selectedSegment.confirmButtonClicked === true}
    />,
  ];

  const tabPanels = [
    <TabPanel
      key="tab-0"
      tabPanelId="tab_0"
    />,
    <TabPanel
      key="tab-1"
      tabPanelId="tab_1"
    />,
  ];

  const tabPanelsRotated = [
    <TabPanel
      key="tab-1"
      tabPanelId="tab_1"
    />,
    <TabPanel
      key="tab-0"
      tabPanelId="tab_0"
    />,
  ];

  return (
    <div id="editorBottomLeftHeaderSection" className={sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === true ? 'editor_bottom_header_margin editor_header_rotate_left' : 'editor_bottom_header_margin'}>
      <Tabs
        tabs={sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === true ? tabsRotated : tabs}
        tabPanels={sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === true ? tabPanelsRotated : tabPanels}
        onTabChanged={changetab}
        defaultActiveTab="tab_0"
        className="tabs_bottom_left"
        activeTab={currentActiveTab}
      />
    </div>
  );
};

export default EditorBottomLeftHeader;
