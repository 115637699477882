import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { dashboardReducer } from './reducers/DashboardpageReducers';
import { searchPageReducer } from './reducers/SearchpageReducers';
import { editorPageReducer } from './reducers/EditorpageReducers';

const rootReducer = combineReducers({
  dashboardPage: dashboardReducer,
  searchPage: searchPageReducer,
  editorPage: editorPageReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
