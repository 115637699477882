/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-boolean-value */

import React, { useContext, useEffect, useState } from 'react';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/banner/style.scss';
import '@ingka/focus/style.scss';
import '../../App.scss';
import Banner from '@ingka/banner';
import { Link } from 'react-router-dom';
import SitsContext from '../../context/SitsContext';
import bannerMessage from '../../messages/bannerMessages.json';

export default function BannerLanding(props) {
  const sitsContext = useContext(SitsContext);
  const [closeBanner, setCloseBanner] = useState(false);

  useEffect(() => {
    const bannerheight = document.getElementById('bannerDiv').clientHeight;
    props.heightofBanner(bannerheight);
  }, [closeBanner]);

  const enablemessage = () => {
    sitsContext.updateEnableBannerMessage(true);
  };
  return (
    <div id="bannerDiv">
      <Banner
        dismissHandler={() => {
          setCloseBanner(true);
          sitsContext.updateBannerVisible(false);
        }}
        body={(
          <span>
            <Link onClick={enablemessage} className="link_nav1">{bannerMessage.bannerHeading}</Link>
          </span>
)}
        dismissable
      />
    </div>
  );
}
