/* eslint-disable react/no-array-index-key */
/* eslint-disable no-import-assign */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import '@ingka/svg-icon/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/toggle/style.scss';
import Toggle from '@ingka/toggle';
import '../dueinData/Dueindatatable.scss';
import { Select, Option } from '@ingka/select';
import FormField from '@ingka/form-field';
import SSRIcon from '@ingka/ssr-icon';
import graphindicator from '@ingka/ssr-icon/paths/square';
import '../../../App.scss';
import { currentFYyear, numberOfFinancialYears } from '../../../constants';

export default function Graphtabs(props) {
  let financialYears = [];

  let seriesforitems = 0;
  let financialyear = currentFYyear.toString();
  //   let result = [];
  //   let response = [];
  let graphvalue = '';
  const [financialyeardata, setfinancialyeardata] = useState([]);
  const [activeindex, setActiveindex] = useState(0);

  useEffect(() => {
    if (financialYears.length === 0) {
      while (seriesforitems <= numberOfFinancialYears) {
        const listOfYears = financialyear--;
        financialYears = [...financialYears, listOfYears];
        seriesforitems++;
      }
    }
    setfinancialyeardata(financialYears);
  }, []);

  const enablebutton = (index) => {
    setActiveindex(index);
    graphvalue = 'texts';
    if (index === 1) {
      graphvalue = 'words';
    }
    props.graphtabvalue(graphvalue);
  };

  const selectedFinancialYear = (e) => {
    props.selectedYear(e.target.value);
  };

  return (
    <div className="graph_tabs">
      <div className="button_tabs">
        <div className="demo-toggle-container">
          <Toggle
            buttons={[
              {
                text: 'Texts',
              },
              {
                text: 'Words',
              },
            ]}
            activeIndex={activeindex}
            onClick={(eventObj, index) => enablebutton(index)}
          />
        </div>
      </div>
      <div>
        <div className="year_sel graph_tabs">
          <FormField className="year_sel_sapce">
            <Select defaultValue={financialyeardata[0]} hintText="" onChange={selectedFinancialYear}>
              {financialyeardata.map((yeardata, dataseq) => (<Option key={dataseq} value={yeardata} name={`FY ${yeardata.toString().slice(2)}`} />))}
            </Select>
          </FormField>
          <div>
            <div>
              <span>
                <SSRIcon className="graph_indicator_new" paths={graphindicator} />
                <span className="graph_legend_label status_plain_text"> New </span>
              </span>
            </div>
            <div>
              <span>
                <SSRIcon className="graph_indicator_released" paths={graphindicator} />
                <span className="graph_legend_label status_plain_text"> Released </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
