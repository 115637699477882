/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import Modal, { Prompt, ModalFooter, ModalHeader } from '@ingka/modal';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/modal/dist/style.css';
import '@ingka/focus/style.scss';
import Button from '@ingka/button';
import SitsContext from '../../../context/SitsContext';
import '../../../App.scss';
import { spaceAfterhundreds } from '../../../constants';

export default function EditorPageReleaseModal(props) {
  const sitsContext = useContext(SitsContext);
  const [modelOpen, setModelOpen] = useState(true);
  // intializing model open to display the modal for API error response
  const onClickCloseHandler = () => {
    setModelOpen(false);
    props.toggleEditorReleaseModal(false);
  }; // Close button fuctionality

  const onClickReleaseHandler = () => {
    setModelOpen(false);
    props.handleReleaseButton();
  }; // Release button fuctionality

  useEffect(() => {
    const headerElement = document.getElementById('editorHeader');
    const releaseModalPrompt = document.getElementById('editorReleaseModalPrompt');
    const appBanner = document.getElementById('bannerDiv');
    if (releaseModalPrompt !== undefined) {
      if (sitsContext.bannerVisible === true) {
        releaseModalPrompt.style.top = `${headerElement.offsetHeight + appBanner.offsetHeight}px`;
      } else {
        releaseModalPrompt.style.top = `${headerElement.offsetHeight}px`;
      }
    }
  }, []);

  return (
    <div className="editorReleaseModal">
      <Modal className="editor-release-popup" handleCloseBtn={onClickCloseHandler} visible={modelOpen} focusLockProps={{ disabled: true }}>
        <Prompt
          aria-label="Accessibility header for a modal"
          className="editor-release-modal-prompt"
          id="editorReleaseModalPrompt"
          footer={(
            <ModalFooter>
              <Button className="btn--small" text="Cancel" onClick={onClickCloseHandler} type="secondary" />
              <Button className="btn--small" text={`Release (${spaceAfterhundreds(props.confirmedTexts)})`} onClick={onClickReleaseHandler} type="primary" />
            </ModalFooter>
        )}
          header={
            <ModalHeader ariaCloseTxt="Close button text" />
          }
          title={`Release ${spaceAfterhundreds(props.confirmedTexts)} of ${spaceAfterhundreds(props.totalTexts)} texts`}
        >
          <div>
            <p>
              Only confirmed text segments will be released. Do you want to release
              {' '}
              <b>
                {spaceAfterhundreds(props.confirmedTexts)}
                {' '}
                of
                {' '}
                {spaceAfterhundreds(props.totalTexts)}
                {' '}
              </b>
              texts?

            </p>
          </div>
        </Prompt>
      </Modal>
    </div>
  );
}
