/* eslint-disable arrow-parens */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import { useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import { useSelector } from 'react-redux';
import SitsContext from './SitsContext';

const SitsProvider = (props) => {
  const totalFilterValues = useSelector((state) => state.searchPage.filtersList);
  const [filterintialvalue, setFilterintialvalue] = useState(false);
  const [enableSearchbuttonDropDwn, setEnableSearchbuttonDropDwn] = useState(false);
  const [concordanceShortcutKey, setconcordanceShortcutKey] = useState(false);
  const [previousState, setPreviousState] = useState({
    data: null,
    source_locale: null,
    target_locale: null,
  });
  const [previousCount, setPreviousCount] = useState();
  const [scrollPosition, setScrollPosition] = useState();
  const [enablebannerMessage, setEnableBannerMessage] = useState(false);
  const [previousSearch, setPreviousSearch] = useState({
    search_value: null,
    searchclick: false,
    selected_locale_code: null,
  });
  const [previousItemsCount, setPreviousItemsCount] = useState({
    objectItemCount: null,
    useridItemCount: null,
    statusItemCount: null,
  });
  const [previousObjectFilter, setPreviousObjectFilter] = useState([]);
  const [previousObjectFilterShortName, setPreviousObjectFilterShortName] = useState([]);
  const [previousUseridFilter, setPreviousUseridFilter] = useState([]);
  const [previousUseridFilterShortName, setPreviousUseridFilterShortName] = useState([]);
  const [previousStatusFilter, setPreviousStatusFilter] = useState([]);
  const [previousStatusFilterShortName, setPreviousStatusFilterShortName] = useState([]);
  const [previousSortFilter, setPreviousSortFilter] = useState([]);
  const [previousSortFilterShortName, setPreviousSortFilterShortName] = useState([]);
  const [previousSelectedFilter, setPreviousSelectedFilter] = useState({
    selectedFiltersName: [],
    selectedFiltersShortname: [],
    selectedFiltersAll: {},
  });
  const [previousFilterHeight, setPreviousFilterHeight] = useState(0);
  const [isPageChange, setIsPageChange] = useState(false);
  const [previousPageNumber, setPreviousPageNumber] = useState(1);
  const [editorpageButton, setEditorpageButton] = useState('loadNewData');
  const [previousScrollSearchResults, setPreviousScrollSearchResults] = useState(0);
  const [currentPageName, setCurrentPageName] = useState('dashboard');
  const [editorStateData, setEditorStateData] = useState({
    selectedPageTitle: null,
    selectedTextData: null,
    sourceLocale: null,
    targetLocale: null,
  });
  const [initialFilterFlag, setInitialFilterFlag] = useState(false);
  const [filterDispFlag, setFilterDispFlag] = useState(false);
  const [checkInitStateFlag, setCheckInitStateFlag] = useState(false);
  const [editToSearchFlag, setEditToSearchFlag] = useState('Dashboard');
  const [langCode, setLangCode] = useState('en-zz');
  const [filtersScrollPosition, setFiltersScrollPostion] = useState({
    objFilter: 0,
    useridFilter: 0,
  });
  const [segmentsData, setSegmentsData] = useState([]);
  const [matchesSuggestionsCount, setMatchesSuggestionsCount] = useState(null);
  const [editorLeftHeader, setEditorLeftHeader] = useState('matches');
  const [editorRightHeader, setEditorRightHeader] = useState('connectedProducts');
  const [concordanceData, setConcordanceData] = useState({
    searchedText: null,
    searchedResults: [],
    scrollPosition: 0,
    tabChange: false,
    noResults: false,
    noMatchesIndicator: false,
  });
  const [matchesData, setMatchesData] = useState(null);
  const [editorSearchPageLocation, setEditorSearchPageLocation] = useState({
    selectedPageTitle: null,
    selectedTextData: null,
    sourceLocale: null,
    targetLocale: null,
  });
  const [selectedSuggestionsDetails, setSelectedSuggestionsDetails] = useState({
    textId: null,
    partId: null,
    partName: null,
    segmentId: null,
    indexId: null,
    translationText: null,
    sourceText: null,
  });
  const [useSuggestionClicked, setUseSuggestionClicked] = useState(false);
  const [selectedlocaleFullName, setSelectedlocaleFullName] = useState(false);
  const [changeMatchesDetailsFlag, setChangeMatchesDetailsFlag] = useState(false);
  const [currentTargetLocale, setCurrentTargetLocale] = useState(null);
  const [startpagepreviousObjectFilter, setStartpagePreviousObjectFilter] = useState([]);
  const [startpagepreviousObjectFilterShortName,
    setStartpagePreviousObjectFilterShortName] = useState([]);
  const [previousDueinFilter, setPreviousDueinFilter] = useState([]);
  const [previousDueinFilterShortName, setPreviousDueinFilterShortName] = useState([]);
  const [previousCategoryFilter, setPreviousCategoryFilter] = useState([]);
  const [previousCategoryFilterShortName, setPreviousCategoryFilterShortName] = useState([]);
  const [startpagepreviousStatusFilter, setStartpagePreviousStatusFilter] = useState([]);
  const [startpagepreviousStatusFilterShortName,
    setStartpagePreviousStatusFilterShortName] = useState([]);
  const [startpagepreviousSortFilter, setStartpagePreviousSortFilter] = useState([]);
  const [startpagepreviousSortFilterShortName,
    setStartpagePreviousSortFilterShortName] = useState([]);
  const [startpageScroll, setStartpageScroll] = useState(null);
  const [dueindataStartpageLoaded, setDueindataStartpageLoaded] = useState(false);
  const [dueindataStartpage, setDueindataStartpage] = useState([]);
  const [graphdataStartpageLoaded, setGraphdataStartpageLoaded] = useState(false);
  const [graphdataStartpage, setGraphdataStartpage] = useState([]);
  const [pendingdataStartpageLoaded, setPendingdataStartpageLoaded] = useState(false);
  const [pendingdataStartpage, setPendingdataStartpage] = useState([]);
  const [dueindataStartpageCountLoaded, setDueindataStartpageCountLoaded] = useState(false);
  const [dueindataStartpageCount, setDueindataStartpageCount] = useState([]);
  const [dueinFilterStartpageCountLoaded, setDueinFilterStartpageCountLoaded] = useState(false);
  const [dueinFilterStartpageCount, setDueinFilterStartpageCount] = useState([]);
  const [popupvalueselected, setPopupvalueselected] = useState(null);
  const [loadintial, setLoadintial] = useState(true);
  const [editorPageRenderFlag, setEditorPageRenderFlag] = useState(false);
  const [checkedtextIds, setCheckedtextIds] = useState([]);
  const [editorResizeMousePosition, setEditorResizeMousePosition] = useState(0);
  const [autoSaveHeaderChange, setAutoSaveHeaderChange] = useState(null);
  const [listOfReleaseTexts, setlistOfReleaseTexts] = useState({
    textIdsToRelease: [],
    releaseButtonStatusCount: [],
  });
  const [editorResizeObject, setEditorResizeObject] = useState({
    resizeFlag: false,
  });
  const [isConnectImageClicked, setIsConnectImageClicked] = useState({
    isImageClicked: false,
    imageIndex: 0,
  });
  const [editorHorizontalDrag, setEditorHorizontalDrag] = useState({
    resizeFlag: false,
    mousePosHorizontal: 0,
    editorLeftWidth: 0,
    editorRightWidth: 0,
  });
  const [connectedProdData, setConnectedProdData] = useState({
    textId: null,
    itemsConnected: [],
  });
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(null);
  const [editorBottomRotateHeader, setEditorBottomRotateHeader] = useState({
    editorLeftHeaderRotate: false,
    editorRightHeaderRotate: false,
  });
  const [contextMenuPostion, setContextMenuPosition] = useState({
    topPositionValue: null,
    leftPositionValue: null,
    rightPositionValue: null,
    bottomPositionValue: null,
    heightEllipsisIcon: null,
  });
  const [contextMenuIconClicked, setContextMenuIconClicked] = useState(false);
  const [selectedMatchesSuggestion, setSelectedMatchesSuggestion] = useState({
    textId: null,
    partId: null,
    partName: null,
    segmentId: null,
    indexId: null,
    translationText: null,
    translationAccuracy: null,
    sourceText: null,
  });
  const [contextMenuOutsideClicked, setContextMenuOutsideClicked] = useState({
    outsideClicked: false,
    ellipsisButtonClicked: false,
  });
  const [bannerVisible, setBannerVisible] = useState(true);
  const [tabNavigation, setTabNavigation] = useState(null);
  const [releasedTextsCount, setReleasedTextsCount] = useState(0);
  const [bulkEditbuttonclicked, setBulkEditbuttonclicked] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    showToastMessage: false,
    toastMessageDescription: '',
  });
  const [editorBottomHeight, setEditorBottomHeight] = useState(0);
  const [editorBottomLeftWidth, setEditorBottomLeftWidth] = useState(0);
  const [editorBottomRightWidth, setEditorBottomRightWidth] = useState(0);
  const [connectedProdRender, setConnectedProdRender] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [historyTabCount, setHistoryTabCount] = useState(null);
  const [actionToStatusMapping, setActionToStatusMapping] = useState({
    autosave: null,
    confirm: null,
    released: null,
  });
  const [paragraphMismatchErrorEditor, setParagraphMismatchErrorEditor] = useState({
    openParagraphMismatchErrorPopup: false,
    paragraphMismatchErrorFlag: false,
    officialLanguageParagraphCount: 0,
    targetLanguageParagraphCount: 0,
    textId: null,
    paragraghMismatchErrorBulkTextDetails: [],
  });
  const [textPartEmptyErrorEditor, setTextPartEmptyErrorEditor] = useState({
    openTextPartEmptyErrorPopup: false,
    textPartEmptyErrorFlag: false,
    textPartNameEmpty: [],
    officialLanguage: '',
    textId: null,
    textPartEmptyErrorBulkTextDetails: [],
  });
  const [numberOfItemsAffected, setNumberOfItemsAffected] = useState({
    openNumberOfItemsAffectedPopup: false,
    numberOfItemsAffectedFlag: false,
    isSegmentsInfoThere: false,
    isRevertChangesLoading: false,
    textId: null,
  });
  const [historyRestoreButtonClicked, setHistoryRestoreButtonClicked] = useState({
    versionName: null,
    restoreFlag: false,
    isRestoreButtonLoading: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [filterValuesList, setFilterValuesList] = useState({
    objectFilter: 0,
    statusFilter: 0,
    dueinFilter: 0,
    userIdFilter: 0,
  });

  const updateFilterValuesList = (filterValue) => {
    setFilterValuesList(prevData => ({
      ...prevData,
      objectFilter: totalFilterValues.objecttypeFilters.some((value) => value.obj_full_name
      === filterValue) ? 1 : 0,
      statusFilter:
      totalFilterValues.statusFilters.some((value) => value.t_status
      === filterValue) ? 1 : 0,
      dueinFilter:
      totalFilterValues.dueinFilters.some((value) => value.due_in_filter_name
      === filterValue) ? 1 : 0,
      userIdFilter:
      totalFilterValues.userIdFilters.some((value) => value.user_Id
      === filterValue) ? 1 : 0,
      categoryFilter:
      totalFilterValues.categoryFilters.some((value) => value.category
      === filterValue) ? 1 : 0,
    }));
  };

  const updatedBannerVisibleHandler = (bannerVisibleFlag) => {
    setBannerVisible(bannerVisibleFlag);
  };

  const updateToastMessageHandler = (showToastMessageFlag, messageDescription) => {
    setToastMessage(prevData => ({
      ...prevData,
      showToastMessage: showToastMessageFlag,
      toastMessageDescription: messageDescription,
    }));
  };

  const updateTabNavigationHandler = (tabNavigationName) => {
    setTabNavigation(tabNavigationName);
  };

  const updateAutoSaveEnabledHandler = (autoSaveFlag) => {
    setAutoSaveEnabled(autoSaveFlag);
  };
  const updateconcordanceShortcutKey = (keypressed) => {
    setconcordanceShortcutKey(keypressed);
  };

  const updateCurrentTargetLocaleHandler = (targetLocale) => {
    setCurrentTargetLocale(targetLocale);
  };

  const updatePreviousStateDataHandler = (
    prevStatedata,
    pagePosition,
    sourceLocale,
    targetLocale,
  ) => {
    setPreviousState(prevData => ({
      ...prevData,
      data: prevStatedata,
      source_locale: sourceLocale,
      target_locale: targetLocale,
    }));
    setScrollPosition(pagePosition);
  };

  const updateTotalCountHandler = (totalCount) => {
    setPreviousCount(totalCount);
  };

  const updatePreviousSearchHandler = (searchBarData) => {
    setPreviousSearch(prevData => ({ ...prevData, ...searchBarData }));
  };

  const updateselectedlocaleFullName = (selectedlocale) => {
    setSelectedlocaleFullName(selectedlocale);
  };

  const updatePreviousItemsCountHandler = (objCount, useridCount, statusCount) => {
    setPreviousItemsCount(prevData => ({
      ...prevData,
      objectItemCount: objCount,
      useridItemCount: useridCount,
      statusItemCount: statusCount,
    }));
  };

  const updatePreviousObjectFilterHandler = (objFilter, objShortName) => {
    setPreviousObjectFilter(objFilter);
    setPreviousObjectFilterShortName(objShortName);
  };

  const updatePreviousDueinFilterHandler = (dueinFilter, dueinShortName) => {
    setPreviousDueinFilter(dueinFilter);
    setPreviousDueinFilterShortName(dueinShortName);
  };

  const updatePreviousCategoryFilterHandler = (categoryFilter, categoryShortName) => {
    setPreviousCategoryFilter(categoryFilter);
    setPreviousCategoryFilterShortName(categoryShortName);
  };

  const updatePreviousUseridFilterHandler = (useridFilter, useridShortName) => {
    setPreviousUseridFilter(useridFilter);
    setPreviousUseridFilterShortName(useridShortName);
  };

  const updatePreviousStatusFilterHandler = (statusFilter, statusShortName) => {
    setPreviousStatusFilter(statusFilter);
    setPreviousStatusFilterShortName(statusShortName);
  };

  const updatePreviousSortFilterHandler = (sortFilter, sortShortName) => {
    setPreviousSortFilter(sortFilter);
    setPreviousSortFilterShortName(sortShortName);
  };

  const updateStartpagePreviousObjectFilterHandler = (objFilters, objShortNames) => {
    setStartpagePreviousObjectFilter(objFilters);
    setStartpagePreviousObjectFilterShortName(objShortNames);
  };

  const updateStartpagePreviousStatusFilterHandler = (statusFilters, statusShortNames) => {
    setStartpagePreviousStatusFilter(statusFilters);
    setStartpagePreviousStatusFilterShortName(statusShortNames);
  };

  const updateStartpagePreviousSortFilterHandler = (sortFilters, sortShortNames) => {
    setStartpagePreviousSortFilter(sortFilters);
    setStartpagePreviousSortFilterShortName(sortShortNames);
  };

  const updatePreviousSelectedFilterHandler = (filtersName, filtersShortname, filtersAll) => {
    setPreviousSelectedFilter(prevData => ({
      ...prevData,
      selectedFiltersName: filtersName,
      selectedFiltersShortname: filtersShortname,
      selectedFiltersAll: filtersAll,
    }));
  };

  const updatePreviousFilterHeightHandler = (filterHeight) => {
    setPreviousFilterHeight(filterHeight);
  };

  const updatePageRedirectionHandler = (isPageRedirected) => {
    setIsPageChange(isPageRedirected);
  };

  const updatePreviousPageNumberHandler = (pageNumber) => {
    setPreviousPageNumber(pageNumber);
  };

  const updatePreviousScrollSearchResultsHandler = (scrollPos) => {
    setPreviousScrollSearchResults(scrollPos);
  };

  const updateCurrentPageNameHandler = (pageName) => {
    setCurrentPageName(pageName);
  };

  const updateEditorPageDataHandler = (pageName, selectedData, sourceLoc, targetLoc) => {
    setEditorStateData(prevData => ({
      ...prevData,
      selectedPageTitle: pageName,
      selectedTextData: selectedData,
      sourceLocale: sourceLoc,
      targetLocale: targetLoc,
    }));
  };

  const updateInitialFlagHandler = (initialFlag) => {
    setInitialFilterFlag(initialFlag);
  };

  const updateFilterDisplayFlagHandler = (filterFlag) => {
    setFilterDispFlag(filterFlag);
  };

  const updateCheckInitStateHandler = (initState) => {
    setCheckInitStateFlag(initState);
  };

  const updateLangCodeDropDwnHandler = (languageCode) => {
    setLangCode(languageCode);
  };

  const resetSearchPageDataHandler = () => {
    setPreviousState({
      data: null,
      source_locale: null,
      target_locale: null,
    });
    setPreviousCount(undefined);
    setScrollPosition(0);
    setPreviousSearch({
      search_value: null,
      searchclick: false,
      selected_locale_code: null,
    });
    setPreviousItemsCount({
      objectItemCount: null,
      useridItemCount: null,
      statusItemCount: null,
    });
    setPreviousObjectFilter([]);
    setPreviousObjectFilterShortName([]);
    setPreviousUseridFilter([]);
    setPreviousUseridFilterShortName([]);
    setPreviousStatusFilter([]);
    setPreviousStatusFilterShortName([]);
    setPreviousSortFilter([]);
    setPreviousSortFilterShortName([]);
    setPreviousSelectedFilter(prevData => ({
      ...prevData,
      selectedFiltersName: [],
      selectedFiltersShortname: [],
      selectedFiltersAll: {},
    }));
    setPreviousFilterHeight(0);
    setIsPageChange(false);
    setPreviousPageNumber(1);
    setPreviousScrollSearchResults(0);
    setEditorStateData({
      selectedPageTitle: null,
      selectedTextData: null,
      sourceLocale: null,
      targetLocale: null,
    });
    setInitialFilterFlag(false);
    setFilterDispFlag(false);
    setCheckInitStateFlag(false);
  };

  const resetDashboardDataHandler = () => {
    setPreviousDueinFilter([]);
    setPreviousDueinFilterShortName([]);
    setStartpageScroll(null);
    setDueindataStartpageLoaded(false);
    setDueindataStartpage([]);
    setDueindataStartpageCountLoaded(false);
    setDueindataStartpageCount([]);
    setDueinFilterStartpageCountLoaded(false);
    setDueinFilterStartpageCount([]);
    setGraphdataStartpageLoaded(false);
    setGraphdataStartpage([]);
    setPendingdataStartpageLoaded(false);
    setPendingdataStartpage([]);
    setStartpagePreviousObjectFilter([]);
    setStartpagePreviousObjectFilterShortName([]);
    setStartpagePreviousStatusFilter([]);
    setStartpagePreviousStatusFilterShortName([]);
    setStartpagePreviousSortFilter([]);
    setStartpagePreviousSortFilterShortName([]);
  };

  // Function to update the flag to true when user goes from edit to search page
  const updateNavigateEditToSearchPageFlagHandler = (navigateFlag) => {
    setEditToSearchFlag(navigateFlag);
  };
  const updateEnableBannerMessageHandler = (enableFlag) => {
    setEnableBannerMessage(enableFlag);
  };
  const updatefilterintialvalue = (filterflag) => {
    setFilterintialvalue(filterflag);
  };
  const updateSearchbuttonDropDwn = (enableFlag) => {
    setEnableSearchbuttonDropDwn(enableFlag);
  };

  const updateCheckedtextIds = (textids) => {
    setCheckedtextIds(textids);
  };

  const updatebulkEditbuttonclicked = (buttonstatus) => {
    setBulkEditbuttonclicked(buttonstatus);
  };

  // Function to update the filters scroll postion (Object type and user id filters)
  const updateFiltersScrollPosHandler = (filterName, scrollPos) => {
    if (filterName === 'objectType') {
      setFiltersScrollPostion(prevData => ({
        ...prevData,
        objFilter: scrollPos,
      }));
    } else if (filterName === 'userId') {
      setFiltersScrollPostion(prevData => ({
        ...prevData,
        useridFilter: scrollPos,
      }));
    }
  };

  // Function to update the segments details for the selected segment
  const updateSegmentsDetailsHandler = (segDetails) => {
    setSegmentsData(segDetails);
  };

  // Function to update the Matches count for the selected segment
  const updateMatchesSuggestionsCountHandler = (matchesCount) => {
    setMatchesSuggestionsCount(matchesCount);
  };

  // Function to update the editor bottom left header name
  const updateEditorBottomLeftHeaderHandler = (headerName) => {
    setEditorLeftHeader(headerName);
  };

  // Function to update the editor bottom Right header name
  const updateEditorBottomRightHeaderHandler = (headerName) => {
    setEditorRightHeader(headerName);
  };

  // Function to update the matches data
  const updateMatchesRetrieveDataHandler = (scrollPos) => {
    setMatchesData(scrollPos);
  };

  // Function to update the concordance data
  const updateConcordanceRetrieveDataHandler = (concPropertyName, concData) => {
    if (concPropertyName === 'multiplePropertyChange') {
      setConcordanceData(prevData => ({
        ...prevData,
        ...concData,
      }));
    } else {
      setConcordanceData(prevData => ({
        ...prevData,
        [concPropertyName]: concData,
      }));
    }
  };

  // eslint-disable-next-line max-len
  const updateEditorSearchPageLocationDataHandler = (page, selectedtext, sourceLocale, targetLocale) => {
    setEditorSearchPageLocation(prevData => ({
      ...prevData,
      selectedPageTitle: page,
      selectedTextData: selectedtext,
      sourceLocale,
      targetLocale,
    }));
  };

  const updateSelectedSuggestionMatchesDetailsHandler = (selectedText) => {
    setSelectedSuggestionsDetails(prevData => ({
      ...prevData,
      textId: selectedText.textId,
      partId: selectedText.partId,
      partName: selectedText.partName,
      segmentId: selectedText.segmentId,
      indexId: selectedText.indexId,
      translationText: selectedText.translationText,
      translationAccuracy: selectedText.translationAccuracy,
      sourceText: selectedText.sourceText,
    }));
  };

  const updateUseSuggestionClickedHandler = (isuseButtonClicked) => {
    setUseSuggestionClicked(isuseButtonClicked);
  };

  const updateChangeMatchesDetailsHandler = (changeMatchDetailFlag) => {
    setChangeMatchesDetailsFlag(changeMatchDetailFlag);
  };

  const updateNumberOfReleasedTextsHandler = (countOfReleasedTexts) => {
    setReleasedTextsCount(countOfReleasedTexts);
  };

  const updateStartpagePresentScroll = (scrollPos) => {
    setStartpageScroll(scrollPos);
  };

  const updateDueindataStartpage = (loaded, dueindata) => {
    setDueindataStartpageLoaded(loaded);
    setDueindataStartpage(dueindata);
  };

  const updateEditorpageButton = (statusButton) => {
    setEditorpageButton(statusButton);
  };

  const updateDueindataStartpageCount = (loaded, dueindata) => {
    setDueindataStartpageCountLoaded(loaded);
    setDueindataStartpageCount(dueindata);
  };

  const updateDueinFilterStartpageCount = (loaded, dueindata) => {
    setDueinFilterStartpageCountLoaded(loaded);
    setDueinFilterStartpageCount(dueindata);
  };

  const updateGraphdataStartpage = (loaded, graphdata) => {
    setGraphdataStartpageLoaded(loaded);
    setGraphdataStartpage(graphdata);
  };

  const updatePendingtranslationsStartpage = (loaded, Pendingdata) => {
    setPendingdataStartpageLoaded(loaded);
    setPendingdataStartpage(Pendingdata);
  };
  const updatepopupvalueselected = (selectedlang) => {
    setPopupvalueselected(selectedlang);
  };

  const updateLoadintial = (boolvalue) => {
    setLoadintial(boolvalue);
  };

  const updateEditorPageRenderHandler = (isEditorPageRendering) => {
    setEditorPageRenderFlag(isEditorPageRendering);
  };

  const updateEditorResizeMousePosHandler = (mousePos) => {
    setEditorResizeMousePosition(mousePos);
  };

  const updateAutoSaveHeaderChangeHangler = (autoSaveChangeFlag) => {
    setAutoSaveHeaderChange(autoSaveChangeFlag);
  };

  const updateEditorResizeObjHandler = (propName, propValue) => {
    setEditorResizeObject(prevData => ({
      ...prevData,
      [propName]: propValue,
    }));
  };

  const updateIsConnectImageClickedHandler = (connectDataPropName, connectedData) => {
    if (connectDataPropName === 'multiplePropertyChange') {
      setIsConnectImageClicked(prevData => ({
        ...prevData,
        ...connectedData,
      }));
    } else {
      setIsConnectImageClicked(prevData => ({
        ...prevData,
        [connectDataPropName]: connectedData,
      }));
    }
  };

  const updateEditorHorizontalDragDataHandler = (editorHorizontalPropName, horData) => {
    if (editorHorizontalPropName === 'multiplePropertyChange') {
      setEditorHorizontalDrag(prevData => ({
        ...prevData,
        ...horData,
      }));
    } else {
      setEditorHorizontalDrag(prevData => ({
        ...prevData,
        [editorHorizontalPropName]: horData,
      }));
    }
  };

  const updateConnectedProdResDataHandler = (connectedProdRes) => {
    setConnectedProdData(prevData => ({
      ...prevData,
      ...connectedProdRes,
    }));
  };

  const updateEditorBottomRotateHeaderHandler = (editorHeaderName, rotateFlag) => {
    setEditorBottomRotateHeader(prevData => ({
      ...prevData,
      [editorHeaderName]: rotateFlag,
    }));
  };

  const updateContextMenuPositionHandler = (positionData) => {
    setContextMenuPosition(prevData => ({
      ...prevData,
      ...positionData,
    }));
  };

  const updateContextMenuIconClickedHandler = (iconClicked) => {
    setContextMenuIconClicked(iconClicked);
  };

  const updateMatchesSuggestionHandler = (selectedMatchesData) => {
    setSelectedMatchesSuggestion(prevData => ({
      ...prevData,
      ...selectedMatchesData,
    }));
  };

  const updateContextMenuOutsideClickedHandler = (clickOutsideFlag) => {
    setContextMenuOutsideClicked(prevData => ({
      ...prevData,
      ...clickOutsideFlag,
    }));
  };

  const updateEditorBottomSectionHeightHandler = (editorDragBottomHeight) => {
    setEditorBottomHeight(editorDragBottomHeight);
  };

  const updateEditorBottomLeftWidthHandler = (editorLeftWidth) => {
    setEditorBottomLeftWidth(editorLeftWidth);
  };

  const updateEditorBottomRightWidthHandler = (editorRightWidth) => {
    setEditorBottomRightWidth(editorRightWidth);
  };

  const updateConnectedProdRenderHandler = (connectedProdRenderFlag) => {
    setConnectedProdRender(connectedProdRenderFlag);
  };

  const updateListOfReleaseTexts = (releasetextlist, statusCountofButton) => {
    setlistOfReleaseTexts(prevData => ({
      ...prevData,
      textIdsToRelease: releasetextlist,
      releaseButtonStatusCount: statusCountofButton,
    }));
  };

  const updateHistoryTabDataHandler = (historyFlag, historyData, historyCount) => {
    setHistoryDetails(historyData);
    setShowHistory(historyFlag);
    setHistoryTabCount(historyCount);
  };

  const updateActionToStatusMappingHandler = (statusMappingData) => {
    setActionToStatusMapping(statusMappingData);
  };

  const updateParagraphMismatchErrorEditorHandler = (paragraphMismatchErrorData) => {
    setParagraphMismatchErrorEditor(prevData => ({
      ...prevData,
      ...paragraphMismatchErrorData,
    }));
  };

  const updateTextpartEmptyErrorEditorHandler = (textPartEmptyErrorData) => {
    setTextPartEmptyErrorEditor(prevData => ({
      ...prevData,
      ...textPartEmptyErrorData,
    }));
  };

  const updateNumberOfItemsAffectedHandler = (numberOfItemsAffectedData) => {
    setNumberOfItemsAffected(prevData => ({
      ...prevData,
      ...numberOfItemsAffectedData,
    }));
  };

  const resetValidationErrorHandler = () => {
    setTextPartEmptyErrorEditor(prevData => ({
      ...prevData,
      openTextPartEmptyErrorPopup: false,
      textPartEmptyErrorFlag: false,
      textPartNameEmpty: [],
      officialLanguage: '',

    }));
    setParagraphMismatchErrorEditor(prevData => ({
      ...prevData,
      openParagraphMismatchErrorPopup: false,
      paragraphMismatchErrorFlag: false,
      officialLanguageParagraphCount: 0,
      targetLanguageParagraphCount: 0,
    }));
  };

  const updateHistoryRestoreButtonClickedHandler = (restoreDetails) => {
    setHistoryRestoreButtonClicked(prevData => ({
      ...prevData,
      ...restoreDetails,
    }));
  };

  const sitsContext = {
    autoSaveEnabled,
    updateAutoSaveEnabled: updateAutoSaveEnabledHandler,
    bannerVisible,
    updateBannerVisible: updatedBannerVisibleHandler,
    toastMessage,
    updateToastMessage: updateToastMessageHandler,
    tabNavigation,
    updateTabNavigation: updateTabNavigationHandler,
    previousStateData: previousState,
    previousStateCount: previousCount,
    updateTotalCount: updateTotalCountHandler,
    scrollOffset: scrollPosition,
    updatePreviousData: updatePreviousStateDataHandler,
    previousSearchData: previousSearch,
    updatePreviousSearchData: updatePreviousSearchHandler,
    previousStateItemsCount: previousItemsCount,
    updatePreviousItemsCount: updatePreviousItemsCountHandler,
    previousStateObjectFilter: previousObjectFilter,
    previousStateObjectFilterShortName: previousObjectFilterShortName,
    updatePreviousObjectFilter: updatePreviousObjectFilterHandler,
    previousStateDueinFilter: previousDueinFilter,
    previousStateDueinFilterShortName: previousDueinFilterShortName,
    updatePreviousDueinFilter: updatePreviousDueinFilterHandler,
    previousStateCategoryFilter: previousCategoryFilter,
    previousStateCategoryFilterShortName: previousCategoryFilterShortName,
    updatePreviousCategoryFilter: updatePreviousCategoryFilterHandler,
    previousStateUseridFilter: previousUseridFilter,
    previousStateUseridFilterShortName: previousUseridFilterShortName,
    updatePreviousUseridFilter: updatePreviousUseridFilterHandler,
    previousStateStatusFilter: previousStatusFilter,
    previousStateStatusFilterShortName: previousStatusFilterShortName,
    updatePreviousStatusFilter: updatePreviousStatusFilterHandler,
    previousStateSortFilter: previousSortFilter,
    previousStateSortFilterShortName: previousSortFilterShortName,
    updatePreviousSortFilter: updatePreviousSortFilterHandler,
    startPagepreviousStateObjectFilter: startpagepreviousObjectFilter,
    startPagepreviousStateObjectFilterShortName: startpagepreviousObjectFilterShortName,
    startPageupdatePreviousObjectFilter: updateStartpagePreviousObjectFilterHandler,
    startPagepreviousStateStatusFilter: startpagepreviousStatusFilter,
    startPagepreviousStateStatusFilterShortName: startpagepreviousStatusFilterShortName,
    startPageupdatePreviousStatusFilter: updateStartpagePreviousStatusFilterHandler,
    startPagepreviousStateSortFilter: startpagepreviousSortFilter,
    startPagepreviousStateSortFilterShortName: startpagepreviousSortFilterShortName,
    startPageupdatePreviousSortFilter: updateStartpagePreviousSortFilterHandler,
    previousStateSelectedFilter: previousSelectedFilter,
    updatePreviousSelectedFilter: updatePreviousSelectedFilterHandler,
    previousStateFilterHeight: previousFilterHeight,
    updatePreviousFilterHeight: updatePreviousFilterHeightHandler,
    pageRedirection: isPageChange,
    updatePageRedirection: updatePageRedirectionHandler,
    previousStatePageNumber: previousPageNumber,
    updatePreviousPageNumber: updatePreviousPageNumberHandler,
    previousStateScrollSearchResults: previousScrollSearchResults,
    updatePreviousScrollSearchResults: updatePreviousScrollSearchResultsHandler,
    currentStatePageName: currentPageName,
    updateCurrentStatePageName: updateCurrentPageNameHandler,
    editorPageStateData: editorStateData,
    updateEditorPageData: updateEditorPageDataHandler,
    initialStateFilterFlag: initialFilterFlag,
    updateInitialFilterFlag: updateInitialFlagHandler,
    filterDisplayFlag: filterDispFlag,
    updateFilterDisplayFlag: updateFilterDisplayFlagHandler,
    checkInitState: checkInitStateFlag,
    updateCheckInitState: updateCheckInitStateHandler,
    resetSearchPageData: resetSearchPageDataHandler,
    resetDashboardPageData: resetDashboardDataHandler,
    navigateEditToSearchPageFlag: editToSearchFlag,
    updateNavigateEditToSearchPageFlag: updateNavigateEditToSearchPageFlagHandler,
    enableBannerMessage: enablebannerMessage,
    updateEnableBannerMessage: updateEnableBannerMessageHandler,
    languageCodeDropDwn: langCode,
    updateLanguageCodeDropDwn: updateLangCodeDropDwnHandler,
    filtersScrollPos: filtersScrollPosition,
    updateFiltersScrollPos: updateFiltersScrollPosHandler,
    segmentsDetails: segmentsData,
    updateSegmentsDetails: updateSegmentsDetailsHandler,
    matchesSuggestionsCount,
    updateMatchesSuggestionsCount: updateMatchesSuggestionsCountHandler,
    editorBottomLeftHeader: editorLeftHeader,
    updateEditorBottomLeftHeader: updateEditorBottomLeftHeaderHandler,
    editorBottomRightHeader: editorRightHeader,
    updateEditorBottomRightHeader: updateEditorBottomRightHeaderHandler,
    concordanceRetrieveData: concordanceData,
    updateConcordanceRetrieveData: updateConcordanceRetrieveDataHandler,
    matchesRetrieveData: matchesData,
    updateMatchesRetrieveData: updateMatchesRetrieveDataHandler,
    editorSearchPageLocationData: editorSearchPageLocation,
    updateEditorSearchPageLocationData: updateEditorSearchPageLocationDataHandler,
    intialfiltervalue: filterintialvalue,
    updateintialfiltervalue: updatefilterintialvalue,
    enablesearchbuttoninfilter: enableSearchbuttonDropDwn,
    updateEnableSearchbuttoninfilter: updateSearchbuttonDropDwn,
    selectedSuggestionMatchesDetails: selectedSuggestionsDetails,
    updateSelectedSuggestionMatchesDetails: updateSelectedSuggestionMatchesDetailsHandler,
    isUseSuggestionClicked: useSuggestionClicked,
    updateUseSuggestionClicked: updateUseSuggestionClickedHandler,
    changeMatchesDetails: changeMatchesDetailsFlag,
    updateChangeMatchesDetails: updateChangeMatchesDetailsHandler,
    numberOfReleasedTexts: releasedTextsCount,
    updateNumberOfReleasedTexts: updateNumberOfReleasedTextsHandler,
    currentTargetLocale,
    updateCurrentTargetLocale: updateCurrentTargetLocaleHandler,
    currentStartPageScroll: startpageScroll,
    updateCurrentStartPageScroll: updateStartpagePresentScroll,
    startpageDueinDataloaded: dueindataStartpageLoaded,
    startpageDueinData: dueindataStartpage,
    updateStartpageDueinData: updateDueindataStartpage,
    startpageDueindataCountloaded: dueindataStartpageCountLoaded,
    startpageDueindataCount: dueindataStartpageCount,
    updateStartpageDueinDataCount: updateDueindataStartpageCount,
    startpageDueinFilterCountloaded: dueinFilterStartpageCountLoaded,
    startpageDueinFilterCount: dueinFilterStartpageCount,
    updateStartpageDueinFilterCount: updateDueinFilterStartpageCount,
    startpageGraphdataloaded: graphdataStartpageLoaded,
    startpageGraphdata: graphdataStartpage,
    updateStartpageGraphdata: updateGraphdataStartpage,
    startpagePendingtranslationsloaded: pendingdataStartpageLoaded,
    startpagePendingtranslations: pendingdataStartpage,
    updateStartpagePendingtranslations: updatePendingtranslationsStartpage,
    selectedPopuplangvalue: popupvalueselected,
    updateselectedPopuplangvalue: updatepopupvalueselected,
    intialloading: loadintial,
    updateintialloading: updateLoadintial,
    editorPageRender: editorPageRenderFlag,
    updateEditorPageRender: updateEditorPageRenderHandler,
    editorResizeMousePos: editorResizeMousePosition,
    updateEditorResizeMousePos: updateEditorResizeMousePosHandler,
    autoSaveHeaderChange,
    updateAutoSaveHeaderChange: updateAutoSaveHeaderChangeHangler,
    editorResizeObj: editorResizeObject,
    updateEditorResizeObj: updateEditorResizeObjHandler,
    isConnectImageClicked,
    updateIsConnectImageClicked: updateIsConnectImageClickedHandler,
    editorHorizontalDragData: editorHorizontalDrag,
    updateEditorHorizontalDragData: updateEditorHorizontalDragDataHandler,
    connectedProdData,
    updateConnectedProdResData: updateConnectedProdResDataHandler,
    editorBottomRotateHeader,
    updateEditorBottomRotateHeader: updateEditorBottomRotateHeaderHandler,
    editorPageStatusButton: editorpageButton,
    updateEditorPageStatusButton: updateEditorpageButton,
    contextMenuPostion,
    updateContextMenuPosition: updateContextMenuPositionHandler,
    contextMenuIconClicked,
    updateContextMenuIconClicked: updateContextMenuIconClickedHandler,
    selectedMatchesSuggestion,
    updateMatchesSuggestion: updateMatchesSuggestionHandler,
    contextMenuOutsideClicked,
    updateContextMenuOutsideClicked: updateContextMenuOutsideClickedHandler,
    selectedtextsforbulkedit: checkedtextIds,
    updateSelectedtextsforbulkedit: updateCheckedtextIds,
    selectedLocaleName: selectedlocaleFullName,
    updateSelectedLocaleName: updateselectedlocaleFullName,
    bulkEditbuttonStatus: bulkEditbuttonclicked,
    updateBulkEditbuttonStatus: updatebulkEditbuttonclicked,
    editorBottomSectionHeight: editorBottomHeight,
    updateEditorBottomSectionHeight: updateEditorBottomSectionHeightHandler,
    editorBottomLeftWidth,
    updateEditorBottomLeftWidth: updateEditorBottomLeftWidthHandler,
    editorBottomRightWidth,
    updateEditorBottomRightWidth: updateEditorBottomRightWidthHandler,
    connectedProdRender,
    updateConnectedProdRender: updateConnectedProdRenderHandler,
    shortcutKeyForConcordance: concordanceShortcutKey,
    updateshortcutKeyForConcordance: updateconcordanceShortcutKey,
    dataOfRelaseTexts: listOfReleaseTexts,
    updateDataOfRelaseTexts: updateListOfReleaseTexts,
    showHistory,
    historyDetails,
    historyTabCount,
    updateHistoryTabData: updateHistoryTabDataHandler,
    actionToStatusMapping,
    updateActionToStatusMapping: updateActionToStatusMappingHandler,
    paragraphMismatchErrorEditor,
    updateParagraphMismatchErrorEditor: updateParagraphMismatchErrorEditorHandler,
    textPartEmptyErrorEditor,
    updateTextpartEmptyErrorEditor: updateTextpartEmptyErrorEditorHandler,
    numberOfItemsAffected,
    updateNumberOfItemsAffected: updateNumberOfItemsAffectedHandler,
    resetValidationError: resetValidationErrorHandler,
    historyRestoreButtonClicked,
    updateHistoryRestoreButtonClicked: updateHistoryRestoreButtonClickedHandler,
    filterValues: filterValuesList,
    updateFilterValues: updateFilterValuesList,
  };

  return (
    <SitsContext.Provider value={sitsContext}>
      {props.children}
    </SitsContext.Provider>
  );
};

export default SitsProvider;
