/* eslint-disable default-param-last */

export const totalTextsSelected = (data) => ({
  type: 'FETCH_SELECTED_TEXTS',
  payload: data,
});

const initialState = {
  posts: [],
};

export const editorPageReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
