/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/carousel/style.scss';
import '@ingka/tabs/style.scss';
import '@ingka/focus/style.scss';
import { Link } from 'react-router-dom';
import '../../App.scss';
import SitsContext from '../../context/SitsContext';
import lablesAndnames from '../../lablesAndnames/labelsForComponents.json';

export default function TabComponent() {
  const sitsContext = useContext(SitsContext);
  // eslint-disable-next-line no-unused-vars
  const [currentDefaultTabName, setCurrentDefaultName] = useState(null);

  useEffect(() => {
    if (sitsContext.tabNavigation !== null) {
      setCurrentDefaultName(sitsContext.tabNavigation);
      sitsContext.updateTabNavigation(null);
    }
  }, [sitsContext.tabNavigation]);

  // Tab change functionality
  const changetab = (e) => {
    sessionStorage.setItem('selectedtab', e); // selectedtab is stored in sesssion storage , to retrive value when the page is reloaded
  };

  const pageRedirectHandler = (pageName, pageId) => {
    sitsContext.updateFilterValues('NoFilters');
    setCurrentDefaultName(pageId);
    sitsContext.updateEnableSearchbuttoninfilter(false);
    sitsContext.updateintialfiltervalue(false);
    if (sitsContext.checkInitState) {
      sitsContext.updateFilterDisplayFlag(false);
      sitsContext.updateInitialFilterFlag(false);
      sitsContext.updatePageRedirection(true);
    }
    sitsContext.updateCurrentStatePageName(pageName);
  };

  const tabs = [
    <Link className="link_nav" to="/Dashboardpage" key="tab_0">
      <Tab
        onClick={() => pageRedirectHandler('dashboard', 'tab_0')}
        text={lablesAndnames.tab_dashboard}
        tabPanelId="tab_0"
      />
    </Link>,

    <Link className="link_nav" state={{ path: 'searchpage' }} to="/Searchpage" key="tab_2">
      <Tab
        onClick={() => pageRedirectHandler('search', 'tab_2')}
        text={lablesAndnames.tab_search}
        tabPanelId="tab_2"
      />
    </Link>,
  ];

  const tabPanels = [
    <TabPanel
      key="tab-0"
      tabPanelId="tab_0"
    />,

    <TabPanel
      key="tab-2"
      tabPanelId="tab_2"
    />,
  ];

  return (

    <div className="Tabs">
      <Tabs
        tabs={tabs}
        tabPanels={tabPanels}
        onTabChanged={changetab}
        activeTab={currentDefaultTabName === null ? (sitsContext.navigateEditToSearchPageFlag === lablesAndnames.tab_search ? 'tab_2' : 'tab_0') : currentDefaultTabName}
      />
    </div>
  );
}
