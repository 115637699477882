/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
import './Errorpage.scss';
import '../../App.scss';
import error from '../../messages/errorMessages.json';

export default function ErrorPage() {
  return (
    <div className="errorPage_container">
      <div className="accessdenied-panel">
        <label className="cursor_default header_label1">SITS</label>
      </div>
      <div className="errormsg-div">
        <div className="errorMessage_container">
          <div className="errormsghdr-div">
            <h1 className="errormsg-head">
              {error.errorOnAccessingHeader}
            </h1>
          </div>
          <div>
            <div className="errormsgbody-div">
              <p className="errormsg-para">
                {error.errorOnAccessingMessage1}
                <br />
                {' '}
                {error.errorOnAccessingMessage2}
              </p>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
}
