/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import React from 'react';
import '../filters/Filtercomponent.scss';
import Skeletonloadingcomponent from '../../../common-components/loader/Skeletonloadingcomponent';
import { spaceAfterhundreds } from '../../../constants';

function DisplayCount(props) {
  if (props.count > 0) {
    if (props.loading === true) {
      return (
        <label className="display_count_align">
          <span className="display_counrt_text">Displaying</span>
          <Skeletonloadingcomponent skeletonwidth="50px" skeletonheight="16px" />
          <span className="display_counrt_text">texts</span>
        </label>
      );
    }
    return (
      <label className="display_count_align">
        <span className="display_counrt_text">Displaying</span>
        {spaceAfterhundreds(props.count)}
        <span className="display_counrt_text">
          {`text${props.count > 1 ? 's' : ''}`}
        </span>
      </label>
    );
  }
}
export default DisplayCount;
