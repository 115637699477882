/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import Skeleton from '@ingka/skeleton';
import '@ingka/skeleton/style.scss';
import React from 'react';

export default function Skeletonloadingcomponent(props) {
  return (
    <div>
      <Skeleton width={props.skeletonwidth} height={props.skeletonheight} />
    </div>
  );
}
