/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useContext } from 'react';
import Calenderfiltercomponent from './Calenderfiltercomponent';
import Objecttypecomponent from './Objecttypecomponent';
import Sortfiltercomponent from './Sortfiltercomponent';
import Statusfiltercomponent from './Statusfiltercomponent';
import Useridfiltercomponent from './Useridfiltercomponent';
import './Filtercomponent.scss';
import DisplayCount from '../displayCount/DisplayCount';
import { filtersarray } from '../../../constants';
import Dueinfiltercomponent from './Dueinfiltercomponent';
import { selectedPage } from '../../../common-components/header/Header';
import SitsContext from '../../../context/SitsContext';
import Categoryfiltercomponent from './Categoryfiltercomponent';

export default function Filtercomponent(props) {
  const sitsContext = useContext(SitsContext);
  const [Objectvalues, setObjectvalues] = useState([]);
  // initialising Objectvalues array to capture selected filters in object filter

  const [Statusvalues, setStatusvalues] = useState([]);
  // initialising Objectvalues array to capture selected filters in status filter

  const [Sortvalues, setSortvalues] = useState([]);
  // initialising Objectvalues array to capture selected filters in object filter

  const [Useridvalues, setUseridvalues] = useState([]);

  const [Dueinvalues, setDueinvalues] = useState([]);
  const [Categoryvalues, setCategoryFiltervalues] = useState([]);

  useEffect(() => {
    // update the filter values whenever there is a change in selected filters
    const filters = {
      selectedfilters: filtersarray.concat(

        Objectvalues.selectedfilters === undefined ? [] : Objectvalues.selectedfilters,
        Categoryvalues.selectedfilters === undefined ? [] : Categoryvalues.selectedfilters,
        Dueinvalues.selectedfilters === undefined ? [] : Dueinvalues.selectedfilters,
        Statusvalues.selectedfilters === undefined ? [] : Statusvalues.selectedfilters,
        Useridvalues.selectedfilters === undefined ? [] : Useridvalues.selectedfilters,

      ),

      selectedfiltersName: filtersarray.concat(

        Objectvalues.selectedfiltersName === undefined ? [] : Objectvalues.selectedfiltersName,
        Categoryvalues.selectedfiltersName === undefined ? [] : Categoryvalues.selectedfiltersName,
        Dueinvalues.selectedfiltersName === undefined ? [] : Dueinvalues.selectedfiltersName,
        Statusvalues.selectedfiltersName === undefined ? [] : Statusvalues.selectedfiltersName,
        Useridvalues.selectedfiltersName === undefined ? [] : Useridvalues.selectedfiltersName,

      ),

      searchselected: Objectvalues.searchselected
      || Statusvalues.searchselected || Useridvalues.searchselected,

      selectedfilterstoapi: {

        Objecttypefilters:
        Objectvalues.selectedfilters === undefined ? [] : Objectvalues.selectedfilters,
        categoryfilters:
        Categoryvalues.selectedfilters === undefined ? [] : Categoryvalues.selectedfilters,
        dueinfilters:
        Dueinvalues.selectedfilters === undefined ? [] : Dueinvalues.selectedfilters,
        Statusfilters:
        Statusvalues.selectedfilters === undefined ? [] : Statusvalues.selectedfilters,
        Sortfilters:
        Sortvalues.selectedfilters === undefined ? '' : Sortvalues.selectedfilters,
        useridfilters:
        Useridvalues.selectedfilters === undefined ? [] : Useridvalues.selectedfilters,
      },
    };
    props.Selectedvalues(filters);
    sitsContext.updateintialfiltervalue(true);
  }, [Objectvalues, Statusvalues, Sortvalues, Useridvalues, Dueinvalues, Categoryvalues]);

  const ObjectFilter = (value) => {
    setObjectvalues(value);
  };

  const DueinFilter = (value) => {
    setDueinvalues(value);
  };

  const CategoryFilter = (value) => {
    setCategoryFiltervalues(value);
  };

  const StatusFilter = (value) => {
    setStatusvalues(value);
  };

  const SortFilter = (value) => {
    setSortvalues(value);
  };

  const UseridFilter = (value) => {
    setUseridvalues(value);
  };

  return (
    <div>
      <div className="filter">
        <div className="eachfilter">
          <Sortfiltercomponent
            SortFilter={(value) => SortFilter(value)}
            filterarray={props.filterscleared}
            filtervaluearray={props.filtersclearedvalue}
          />
        </div>

        <div className="eachfilter">
          <Objecttypecomponent
            ObjectFilter={(value) => ObjectFilter(value)}
            filterarray={props.filterscleared}
            filtervaluearray={props.filtersclearedvalue}
            objectCount={props.objCount}
            locationState={props.locationValue}
          />
        </div>

        {selectedPage === 'Dashboard' ? (
          <div className="eachfilter">
            <Categoryfiltercomponent
              categoryFilter={(value) => CategoryFilter(value)}
              filterarray={props.filterscleared}
              filtervaluearray={props.filtersclearedvalue}
              categoryFilterCount={props.categoryCount}
            />
          </div>
        ) : ''}

        {selectedPage === 'Dashboard' ? (
          <div className="eachfilter">
            <Dueinfiltercomponent
              DueinFilter={(value) => DueinFilter(value)}
              filterarray={props.filterscleared}
              filtervaluearray={props.filtersclearedvalue}
              dueinFilterCount={props.dueincount}
            />
          </div>
        ) : ''}

        {selectedPage === 'Dashboard' ? '' : (
          <div className="eachfilter">
            <Useridfiltercomponent
              UseridFilter={(value) => UseridFilter(value)}
              filterarray={props.filterscleared}
              filtervaluearray={props.filtersclearedvalue}
              userIdCount={props.userCount}
            />
          </div>
        )}

        {selectedPage === 'Dashboard' ? '' : (
          <div className="eachfilter">
            <Calenderfiltercomponent />
          </div>
        )}
        <div className="eachfilter">
          <Statusfiltercomponent
            StatusFilter={(value) => StatusFilter(value)}
            filterarray={props.filterscleared}
            filtervaluearray={props.filtersclearedvalue}
            statCount={props.statusCount}
          />
        </div>
        <div className="display_count">
          {props.ShowCount
           && <DisplayCount count={props.ResultCount} loading={props.countloading} />}
        </div>
      </div>
    </div>
  );
}
