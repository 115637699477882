/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useContext } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
// import { Pendingpagedata1 } from '../data/Startpagedata';
import './Pendingtranslations.scss';
import '../../../App.scss';
import { useCookies } from 'react-cookie';
import SSRIcon from '@ingka/ssr-icon';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import { selectedPopupValue } from '../../../App';
import { retrievePendingdata } from '../../../api/StartpageApirequests';
import Skeletonloadingcomponent from '../../../common-components/loader/Skeletonloadingcomponent';
import SitsContext from '../../../context/SitsContext';
import { spaceAfterhundreds } from '../../../constants';
import ErrorHandler from '../../../common-components/errorhandler/ErrorHandler';

export default function Pendingtranslations(props) {
  const sitsContext = useContext(SitsContext);
  const [countloading, setcountloading] = useState(false);
  const [langvalue, setlangvalue] = useCookies(['selected_locale']);
  const [correlationId, setCorrelationId] = useState(1);
  // const [selectedlangvalue, setSelectedlangvalue] = useState(null);
  const [previouslangvalue, setPreviouslangvalue] = useState(null);
  const [updatedvalues, setUpdatedvalues] = useState([
    {
      Status: 'New',
      No_of_texts: '0',
      No_of_words: '0',
    },
    {
      Status: 'Modified',
      No_of_texts: '0',
      No_of_words: '0',
    },
    {
      Status: 'In progress',
      No_of_texts: '0',
      No_of_words: '0',
    },
    {
      Status: 'Translated',
      No_of_texts: '0',
      No_of_words: '0',
    },
    {
      Status: 'Total',
      No_of_texts: '0',
      No_of_words: '0',
    },
  ]);

  useEffect(() => {
    if (sitsContext.editorPageStatusButton === 'loadPreviousData') {
      if (sitsContext.startpagePendingtranslations.length !== 0
      && sitsContext.startpagePendingtranslationsloaded === true) {
        setnewvalues(sitsContext.startpagePendingtranslations);
      } else {
        setcountloading(true);
        retrievePendingdata(langvalue.selected_locale, getpendingdata);
      }
    } else {
      if (langvalue.selected_locale !== undefined) {
        setcountloading(true);
        retrievePendingdata(langvalue.selected_locale, getpendingdata);
      }
    }
  }, []);

  useEffect(() => {
    setPreviouslangvalue(selectedPopupValue.selectedlangvalue);
    if (selectedPopupValue.selectedlangvalue !== previouslangvalue
      && previouslangvalue !== null && previouslangvalue !== undefined) {
      setcountloading(true);
      retrievePendingdata(selectedPopupValue.selectedlangvalue, getpendingdata);
    } else {
      if (selectedPopupValue.selectedlangvalue !== undefined
        && sitsContext.startpagePendingtranslations.length === 0) {
        setcountloading(true);
        retrievePendingdata(selectedPopupValue.selectedlangvalue, getpendingdata);
      }
    }
  }, [selectedPopupValue.selectedlangvalue]);

  const getpendingdata = (response) => {
    if (response.status === 200) {
      sitsContext.updateStartpagePendingtranslations(false, []);
      setnewvalues(response.data);
      sitsContext.updateStartpagePendingtranslations(true, response.data);
      setcountloading(false);
    }
    if (response.status !== 200) {
      setCorrelationId(response.data.CorrelationID);
      sitsContext.updateStartpagePendingtranslations(false, []);
      setUpdatedvalues(updatedvalues);
      setcountloading(false);
    }
  };

  function setnewvalues(pendingdatafromapi) {
    let isItThere = false;
    let statusIndividualData = {};
    if (pendingdatafromapi !== undefined || pendingdatafromapi !== null) {
      const statusDetail = updatedvalues.map((statusData) => {
        pendingdatafromapi[0].groupedStatus.forEach((pending) => {
          if (pending.group_Name.toUpperCase() === statusData.Status.toUpperCase()) {
            isItThere = true;
            statusIndividualData = {
              ...statusData,
              No_of_texts: pending.no_of_texts,
              No_of_words: pending.no_of_words,
            };
          }
        });
        if (isItThere) {
          isItThere = false;
          return statusIndividualData;
        }
        return {
          ...statusData,
          No_of_texts: '0',
          No_of_words: '0',
        };
      });
      setUpdatedvalues(statusDetail);
    }
  }

  return (
    <div className="pending_translation_container">
      <div>
        <Table fullWidth>
          <TableHeader sticky className="cursor_default_default">
            <tr>
              <th colSpan="4" className="pending_padding_1 pending_heading">Pending translations</th>
            </tr>
          </TableHeader>
          <TableBody className="td-body-remove-bordercolor cursor_default_default">
            <tr className="hide-background-color-dupl">
              <td className="pending_padding_1 pending_sub_heading">Status</td>
              <td className="pending_padding_2 pending_sub_heading">No. of texts</td>
              <td className="pending_padding_2 pending_sub_heading">No. of words</td>
            </tr>
            {updatedvalues.map((countdata, dataindex) => (
              <tr key={dataindex} className="hide-background-color-dupl">
                <td className="pending_padding_1 pending_content">{countdata.Status}</td>
                {countloading === true ? <td className="pending_padding_2 pending_content"><Skeletonloadingcomponent skeletonwidth="100%" skeletonheight="16px" /></td>
                  : (
                    <td className="pending_padding_2 pending_content">
                      {(countdata.No_of_texts === '0') ? '-' : spaceAfterhundreds(countdata.No_of_texts) }
                      {' '}
                    </td>
                  )}
                {countloading === true ? <td className="pending_padding_2 pending_content"><Skeletonloadingcomponent skeletonwidth="100%" skeletonheight="16px" /></td>
                  : <td className="pending_padding_2 pending_content">{(countdata.No_of_words === '0') ? '-' : spaceAfterhundreds(countdata.No_of_words) }</td>}
              </tr>
            ))}
          </TableBody>
        </Table>
      </div>
      { correlationId !== 1 ? (
        <div className="error_loading_data">
          <span>
            {' '}
            <SSRIcon className="icon_incorrect" paths={incorrect} />
            {' '}
            Error in loading data
          </span>
        </div>
      ) : ''}
      { correlationId !== 1 ? <ErrorHandler errorDisplayed={11} correlationIdForCountLoad={correlationId} /> : ''}
    </div>
  );
}
