import React from 'react';
import Pill from '@ingka/pill';
import '@ingka/svg-icon/style.scss';
import '@ingka/pill/style.scss';
import '@ingka/focus/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';

export default function Calenderfiltercomponent() {
  return (
    <div>
      <div>
        <Pill
          className="Noto_font_family"
          disabled
          small
          label={lablesAndnames.filter_labelForDatefilter}
          iconPosition="trailing"
          ssrIcon={downarrow}
        />
      </div>
    </div>
  );
}
