/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
import { selectedPage } from '../../../common-components/header/Header';

let gSortValues = [];

const sortFilterValue = () => {
  if (selectedPage === 'Dashboard') {
    gSortValues = [
      {
        sort_filter_value: '',
        sort_filter_name: 'Due in',
      },
      {
        sort_filter_value: 'Object type',
        sort_filter_name: 'Object type',
      },
    ];
  } else if (selectedPage !== 'Dashboard') {
    gSortValues = [
      {
        sort_filter_value: '',
        sort_filter_name: 'Best match',
      },
      {
        sort_filter_value: 'Object type',
        sort_filter_name: 'Object type',
      },
      {
        sort_filter_value: 'Status',
        sort_filter_name: 'Status',
      },
    ];
  }
};

export {
  sortFilterValue,
  gSortValues,
};
