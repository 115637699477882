/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
import Loading, { LoadingBall } from '@ingka/loading';
import '@ingka/loading/style.scss';
import '../../App.scss';

export default function Loadingcomponent(props) {
  return (
    <div className="loading-ball-default">
      {/* <Loading text="Loading your results">
        <LoadingBall />
      </Loading> */}
      <Loading text={(props.loadingText === null || props.loadingText === undefined) ? '' : props.loadingText}>
        <LoadingBall />
      </Loading>
    </div>
  );
}
