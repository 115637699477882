/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, {
  useContext, useEffect,
} from 'react';
import Cookies from 'js-cookie';
import './EditorBottomRightContainer.scss';
import EditorBottomRightHeader from '../editorBottomRightHeader/EditorBottomRightHeader';
import SitsContext from '../../../../context/SitsContext';
// import HandleIcon from './Handle.svg';
import ConnectedProducts from '../connectedProducts/ConnectedProducts';
import HistoryMain from '../history/HistoryMain';
import HandleIcon from './Handle.svg';

const EditorBottomRightContainer = (props) => {
  const sitsContext = useContext(SitsContext);
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    if (sitsContext.editorHorizontalDragData.editorRightWidth !== 0) {
      const bottomRightSectionElement = document.getElementById('editorBottomRight');
      bottomRightSectionElement.style.width = `${sitsContext.editorHorizontalDragData.editorRightWidth}px`;
    }
  }, [sitsContext.editorHorizontalDragData.editorRightWidth]);

  // Editor Right Section mouse handler functions

  const onMouseDownEditorRightHandler = (e) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight; // Whole page height
    const bottomSectionElement = document.getElementById('editorBottomSection');
    const topSectionHeight = (viewportHeight - (bottomSectionElement.offsetHeight)) + 25;
    const bottomRightSectionElement = document.getElementById('editorBottomRight');
    const remainingWidth = (viewportWidth - (bottomRightSectionElement.offsetWidth)) + 25;
    if (topSectionHeight < e.clientY) {
      if (sitsContext.editorResizeObj.resizeFlag === false) {
        if ((remainingWidth) > (e.clientX)) {
          const horData = {
            mousePosHorizontal: e.clientX,
            resizeFlag: true,
          };
          sitsContext.updateEditorHorizontalDragData('multiplePropertyChange', horData);
        } else if ((remainingWidth) < (e.clientX)) {
          sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
        }
      }
    } else if (topSectionHeight > e.clientY) {
      sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
    }
  };

  const onMouseUpEditorRightHandler = () => {
    sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
  };

  useEffect(() => {
    if (sitsContext.editorBottomRotateHeader.editorRightHeaderRotate === true) {
      const bottomLeftSectionElement = document.getElementById('editorBottomLeft');
      const bottomRightSectionElement = document.getElementById('editorBottomRight');
      const headerElement = document.getElementById('editorBottomRightHeaderSection');
      const headerElementValues = headerElement.getBoundingClientRect();
      const bottomRightWidth = window.innerWidth - (headerElementValues.left);
      const bottomLeftWidth = headerElementValues.left;
      sitsContext.updateEditorBottomLeftWidth(bottomLeftWidth);
      Cookies.set('editorBottomLeftWidth', bottomLeftWidth);
      sitsContext.updateEditorBottomRightWidth(bottomRightWidth);
      Cookies.set('editorBottomRightWidth', bottomRightWidth);
      bottomLeftSectionElement.style.width = `${bottomLeftWidth}px`;
      bottomRightSectionElement.style.width = `${bottomRightWidth}px`;
      bottomRightSectionElement.style.cursor = 'default';
      sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
    }
  }, [sitsContext.editorBottomRotateHeader.editorRightHeaderRotate]);

  // eslint-disable-next-line no-unused-vars
  const onMouseOverEditorRightHandler = (e) => {
    let remainingWidth;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight; // Whole page height
    const bottomSectionElement = document.getElementById('editorBottomSection');
    const topSectionHeight = (viewportHeight - (bottomSectionElement.offsetHeight)) + 25;
    const bottomLeftSectionElement = document.getElementById('editorBottomLeft');
    const bottomRightSectionElement = document.getElementById('editorBottomRight');
    const calcThresholdWidth = (85 * (window.innerWidth)) / 100;

    if (sitsContext.editorBottomLeftWidth < calcThresholdWidth) {
      if (sitsContext.editorBottomRotateHeader.editorRightHeaderRotate === true) {
        Cookies.set('editorRightSectionRotated', false);
        sitsContext.updateEditorBottomRotateHeader('editorRightHeaderRotate', false);
      }
    } else if (sitsContext.editorBottomLeftWidth > calcThresholdWidth) {
      if (sitsContext.editorBottomRotateHeader.editorRightHeaderRotate === false) {
        Cookies.set('editorRightSectionRotated', true);
        sitsContext.updateEditorBottomRotateHeader('editorRightHeaderRotate', true);
        return;
      }
    }
    if (topSectionHeight < e.clientY) {
      if (sitsContext.editorHorizontalDragData.resizeFlag === true) {
      // eslint-disable-next-line max-len
        remainingWidth = (viewportWidth - (bottomRightSectionElement.offsetWidth)) + 120;
      } else {
        remainingWidth = (viewportWidth - (bottomRightSectionElement.offsetWidth)) + 25;
      }
      if (sitsContext.editorResizeObj.resizeFlag === false) {
        if ((remainingWidth) > (e.clientX)) {
          bottomRightSectionElement.style.cursor = 'ew-resize';
        } else if ((remainingWidth) < (e.clientX)) {
          bottomRightSectionElement.style.cursor = 'default';
        }
      }
      if (sitsContext.editorHorizontalDragData.resizeFlag === true
      && (sitsContext.editorResizeObj.resizeFlag === false)) {
        const dx = (sitsContext.editorHorizontalDragData.mousePosHorizontal) - e.clientX;
        sitsContext.updateEditorHorizontalDragData('mousePosHorizontal', e.clientX);
        // eslint-disable-next-line prefer-template, radix
        const bottomRightWidth = parseInt(getComputedStyle(bottomRightSectionElement, '').width) + dx;
        const bottomLeftWidth = viewportWidth - bottomRightWidth;
        sitsContext.updateEditorBottomLeftWidth(bottomLeftWidth);
        Cookies.set('editorBottomLeftWidth', bottomLeftWidth);
        sitsContext.updateEditorBottomRightWidth(bottomRightWidth);
        Cookies.set('editorBottomRightWidth', bottomRightWidth);
        bottomLeftSectionElement.style.width = `${bottomLeftWidth}px`;
        bottomRightSectionElement.style.width = `${bottomRightWidth}px`;
      }
    } else if (topSectionHeight > e.clientY) {
      bottomRightSectionElement.style.cursor = 'default';
    }
  };

  const onMouseDownHandler = (e) => {
    props.mouseDownHandler(e);
  };

  const onMouseUpHandler = () => {
    props.mouseUpHandler();
  };

  const onMouseOverHandler = (e) => {
    props.mouseMoveHandler(e);
  };

  return (
    <div
      className="bottom_right_child"
      id="editorBottomRight"
      onMouseMove={(e) => onMouseOverEditorRightHandler(e)}
      onMouseDown={(e) => onMouseDownEditorRightHandler(e)}
      onMouseUp={onMouseUpEditorRightHandler}
    >
      <div
        className="handleIcon"
        onMouseDown={(e) => onMouseDownHandler(e)}
        onMouseUp={onMouseUpHandler}
        onMouseMove={(e) => onMouseOverHandler(e)}
      >
        <img src={HandleIcon} alt="handleIcon" />
      </div>
      <EditorBottomRightHeader
        selectedSegment={props.segmentSelected}
        showHistoryTab={props.showHistoryTabFlag}
      />
      {(sitsContext.editorBottomRightHeader === 'connectedProducts' && sitsContext.connectedProdRender === true
          && sitsContext.editorBottomRotateHeader.editorRightHeaderRotate === false
          && props.segmentSelected.confirmButtonClicked === false
          && props.segmentSelected.initialRendering === false) && (
          <ConnectedProducts
            selectedSegment={props.segmentSelected}
          />
      )}
      {(sitsContext.editorBottomRightHeader === 'connectedProducts' && sitsContext.connectedProdRender === false
          && sitsContext.editorBottomRotateHeader.editorRightHeaderRotate === false
          && props.segmentSelected.confirmButtonClicked === false
          && props.segmentSelected.initialRendering === false) && (
          <ConnectedProducts
            selectedSegment={props.segmentSelected}
          />
      )}
      {((sitsContext.editorBottomRightHeader === 'history'
          && props.segmentSelected.confirmButtonClicked === false)
          && props.segmentSelected.initialRendering === false)
           && (
           <HistoryMain
             selectedSegment={props.segmentSelected}
           />
           )}
    </div>

  );
};

export default EditorBottomRightContainer;
