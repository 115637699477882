/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect } from 'react';
import '@ingka/svg-icon/style.scss';
import '@ingka/accordion/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/button/style.scss';
import '@ingka/tooltip/style.scss';
import '../../../../App.scss';
import SSRIcon from '@ingka/ssr-icon';
import Accordion, { AccordionItem } from '@ingka/accordion';
import information from '@ingka/ssr-icon/paths/information-circle';
import Tooltip from '@ingka/tooltip';
import { notReleasedStatus } from '../../../../constants';
import SitsContext from '../../../../context/SitsContext';
import negativeFlowMsg from '../../../../messages/negativeFlowMessages.json';
import './HistoryMain.scss';

export default function HistoryMain(props) {
  const sitsContext = useContext(SitsContext);
  // eslint-disable-next-line no-unused-vars
  const [enableCurrentVersionAccordion, setEnableCurrentVersionAccordion] = useState(false);
  const [enableDraftVersionAccordion, setEnableDraftVersionAccordion] = useState(false);
  const [currentVersionOpen, setCurrentVersionOpen] = useState(false);
  const [draftVersionOpen, setDraftVersionOpen] = useState(false);

  const restoreCurrentVersionOnClickHandler = () => {
    const restoreDataLocal = {
      versionName: 'currentVersion',
      restoreFlag: true,
    };
    sitsContext.updateHistoryRestoreButtonClicked(restoreDataLocal);
  };

  const restoredraftVersionOnClickHandler = () => {
    const restoreDataLocal = {
      versionName: 'draftVersion',
      restoreFlag: true,
    };
    sitsContext.updateHistoryRestoreButtonClicked(restoreDataLocal);
  };

  // eslint-disable-next-line no-unused-vars
  const [currentVersionEnabledRestoreNode, setCurrentVersionEnabledRestoreNode] = useState(
    <div className="HistoryTitle__Restore">
      <div className="HistoryTitle__Restore-Version">Version (Current)</div>
      <div className="HistoryTitle__Restore-Link" onClick={restoreCurrentVersionOnClickHandler}>
        Restore
      </div>
    </div>,
  );

  // eslint-disable-next-line no-unused-vars
  const [currentVersionDisabledRestoreNode, setCurrentVersionDisabledRestoreNode] = useState(
    <div className="HistoryTitle__Restore">
      <div className="HistoryTitle__Restore-Version">
        Version (Current)
      </div>
      <div>
        <Tooltip
          className="Restore__Disabled-tooltip"
          describedById="tooltipRestoreDisabled"
          tooltipButtonLabel="Restore disabled"
          position="top"
          tooltipText="Segmented translations are not available. To restore, copy and paste the text."
          toggle={false}
          boundaryContainerID="historyContainer"
        >
          <div className="HistoryTitle__Restore-Link-Disabled" id="disabledRestoreLink">
            Restore
          </div>
        </Tooltip>
      </div>
    </div>,
  );

  // eslint-disable-next-line no-unused-vars
  const [draftVersionEnabledRestoreNode, setdraftVersionEnabledRestoreNode] = useState(
    <div className="HistoryTitle__Restore">
      <div className="HistoryTitle__Restore-Version">Version (Draft)</div>
      <div className="HistoryTitle__Restore-Link" onClick={restoredraftVersionOnClickHandler}>
        Restore
      </div>
    </div>,
  );

  // eslint-disable-next-line no-unused-vars
  const [draftVersionDisabledRestoreNode, setDraftVersionDisabledRestoreNode] = useState(
    <div className="HistoryTitle__Restore">
      <div className="HistoryTitle__Restore-Version">
        Version (Draft)
      </div>
      <div>
        <Tooltip
          className="Restore__Disabled-tooltip"
          describedById="tooltipRestoreDisabled"
          tooltipButtonLabel="Restore disabled"
          position="top"
          tooltipText="Segmented translations are not available. To restore, copy and paste the text."
          toggle={false}
          boundaryContainerID="historyContainer"
        >
          <div className="HistoryTitle__Restore-Link-Disabled" id="disabledRestoreLink">
            Restore
          </div>
        </Tooltip>
      </div>
    </div>,
  );

  useEffect(() => {
    const tooltipDisabled = document.getElementById('tooltipRestoreDisabled');
    const restoreDisabled = document.getElementById('disabledRestoreLink');
    if ((tooltipDisabled !== undefined && tooltipDisabled !== null)
    && (restoreDisabled !== undefined && restoreDisabled !== null)) {
      tooltipDisabled.style.height = '50px';
      const restoreElementPosition = restoreDisabled.getBoundingClientRect();
      const tooltipDisabledElementPosition = tooltipDisabled.getBoundingClientRect();
      const restoreElementRightPosValue = window.innerWidth - (restoreElementPosition.right);
      const toolTipElementRightPosValue = window.innerWidth - (tooltipDisabledElementPosition.right);
      tooltipDisabled.style.top = '0px';
      tooltipDisabled.style.left = `${restoreElementRightPosValue - toolTipElementRightPosValue}px`;
      tooltipDisabled.style.bottom = '0px';
      tooltipDisabled.style.right = '0px';
    }
  }, [currentVersionOpen, enableCurrentVersionAccordion]);

  useEffect(() => {
    if (sitsContext.showHistory) {
      sitsContext.historyDetails.forEach((historyData) => {
        if (historyData.currentVersion !== null) {
          if ((historyData.currentVersion.textValue[0].value !== ''
        || historyData.currentVersion.textValue[0].value !== null)
        && (historyData.currentVersion.textValue[0].segments !== null
          && historyData.currentVersion.textValue[0].segments.length !== 0)) {
            if (props.selectedSegment.statusOfTheText === 'modified') {
              setEnableCurrentVersionAccordion(true);
            } else if (historyData.draftVersion !== null) {
              setEnableCurrentVersionAccordion(true);
            } else {
              setEnableCurrentVersionAccordion(false);
            }
          } else {
            setEnableCurrentVersionAccordion(true);
          }
        }
        if (historyData.draftVersion !== null) {
          if (historyData.draftVersion.status.toLowerCase() === 'modified') {
            setEnableDraftVersionAccordion(true);
          } else if (enableDraftVersionAccordion !== false) {
            setEnableDraftVersionAccordion(false);
          }
        }
      });
    }
  }, [sitsContext.showHistory, sitsContext.historyDetails, sitsContext.historyTabCount]);

  const onClickDraftVersionHeadingHandler = () => {
    if (enableDraftVersionAccordion === true) {
      setDraftVersionOpen(!draftVersionOpen);
    }
  };

  const onClickCurrentVersionHeadingHandler = () => {
    if (enableCurrentVersionAccordion === true) {
      setCurrentVersionOpen(!currentVersionOpen);
    }
  };

  return (
    <div
      className={
      sitsContext.historyTabCount === 0
        ? 'history_container_noresults' : 'history_container_allresults'
    }
      id="historyContainer"
    >
      {
        (sitsContext.historyTabCount === 0) && (
          <div className="no_results_bottomtab">
            <div>
              <SSRIcon className="information_circle_color" paths={information} />
            </div>
            <div className="no_results_text_styling">{negativeFlowMsg.noHistoryForText}</div>
          </div>
        )
      }
      {(sitsContext.historyTabCount !== null && sitsContext.historyTabCount !== 0) && (
      <Accordion size="medium">
        {
          sitsContext.historyDetails.map((historyData, historyIndex) => (
            <>
              {
              (historyData.draftVersion !== undefined && historyData.draftVersion !== null)
              && (
              <AccordionItem
                id={historyIndex}
                title={
                  (enableDraftVersionAccordion === true)
                    ? ((draftVersionOpen === true)
                      ? ((historyData.draftVersion.textValue[0].segments !== null
                        && historyData.draftVersion.textValue[0].segments.length !== 0)
                        ? draftVersionEnabledRestoreNode : draftVersionDisabledRestoreNode)
                      : 'Version (Draft)')
                    : 'Version (Draft)'
                }
                caption={
                `${historyData.draftVersion.updatedOn.split('T')[0]}
                | ${historyData.draftVersion.translatedBy}
                | ${
                  historyData.draftVersion.status.toLowerCase() === 'released'
                    ? 'Released'
                    : (historyData.draftVersion.status.toLowerCase() === 'modified'
                      ? notReleasedStatus : `${historyData.draftVersion.status[0].toUpperCase()
                    + historyData.draftVersion.status.slice(1)} (${notReleasedStatus})`)
                  }`
                }
                className={
                  enableDraftVersionAccordion ? 'history_accordion_title'
                    : 'history_accordion_title history_accordion_draft_version'
                }
                onHeadingClicked={onClickDraftVersionHeadingHandler}
                open={draftVersionOpen}
              >
                {(historyData.draftVersion.textValue.length === 1) && enableDraftVersionAccordion && (
                <div className="history_accordion_content">
                  {historyData.draftVersion.textValue[0].value}
                </div>
                )}
                {historyData.draftVersion.textValue.length > 1 && enableDraftVersionAccordion && (
                <div className="history_accordion_content_multiple_textpart_parent">
                  {historyData.draftVersion.textValue.map((textPartValue) => (
                    (
                      textPartValue.value !== '' && textPartValue.value !== null && (
                        <div className="history_accordion_content_multiple_textpart_child">
                          <div className="history_accordion_content_multiple_textpart_name">
                            {textPartValue.partName}
                          </div>
                          <div className="history_accordion_content_multiple_textpart_value">
                            {textPartValue.value}
                          </div>
                        </div>
                      )
                    )
                  ))}
                </div>
                )}
              </AccordionItem>
              )
             }
              {(historyData.currentVersion !== undefined && historyData.currentVersion !== null)
              && (
              <AccordionItem
                id={historyIndex + 1}
                title={
                    (enableCurrentVersionAccordion === true)
                      ? (currentVersionOpen === true
                        ? ((historyData.currentVersion.textValue[0].segments !== null
                          && historyData.currentVersion.textValue[0].segments.length !== 0)
                          ? currentVersionEnabledRestoreNode : currentVersionDisabledRestoreNode)
                        : ('Version (Current)'))
                      : ('Version (Current)')
                }
                caption={`${historyData.currentVersion.updatedOn.split('T')[0]}
                | ${historyData.currentVersion.translatedBy}
                | ${(historyData.currentVersion.status !== '' && historyData.currentVersion.status !== null)
                  ? historyData.currentVersion.status[0].toUpperCase() + historyData.currentVersion.status.slice(1)
                  : ''}`}
                className={enableCurrentVersionAccordion ? 'history_accordion_title' : 'history_accordion_title history_accordion_draft_version'}
                onHeadingClicked={onClickCurrentVersionHeadingHandler}
                open={currentVersionOpen}
              >
                {historyData.currentVersion.textValue.length === 1 && enableCurrentVersionAccordion && (
                <div className="history_accordion_content">
                  {historyData.currentVersion.textValue[0].value}
                </div>
                )}
                {historyData.currentVersion.textValue.length > 1 && enableCurrentVersionAccordion && (
                <div className="history_accordion_content_multiple_textpart_parent">
                  {historyData.currentVersion.textValue.map((textPartValue) => (
                    (
                      textPartValue.value !== '' && textPartValue.value !== null && (
                        <div className="history_accordion_content_multiple_textpart_child">
                          <div className="history_accordion_content_multiple_textpart_name">
                            {textPartValue.partName}
                          </div>
                          <div className="history_accordion_content_multiple_textpart_value">
                            {textPartValue.value}
                          </div>
                        </div>
                      )
                    )
                  ))}
                </div>
                )}
              </AccordionItem>
              )}
            </>
          ))
        }
      </Accordion>
      )}
    </div>
  );
}
