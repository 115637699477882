/* eslint-disable no-unused-vars */
import React from 'react';

const SitsContext = React.createContext({
  previousStateData: {},
  previousStateCount: null,
  updateTotalCount: (totalCount) => {},
  scrollOffset: null,
  updatePreviousData: (stateData) => {},
  previousSearchData: {},
  updatePreviousSearchData: (searchData) => {},
  previousStateItemsCount: {},
  updatePreviousItemsCount: (objCount, useridCount, statusCount) => {},
  previousStateObjectFilter: [],
  previousStateObjectFilterShortName: [],
  updatePreviousObjectFilter: (objFilter) => {},
  previousStateDueinFilter: [],
  previousStateDueinFilterShortName: [],
  updatePreviousDueinFilter: (dueinFilter) => {},
  previousStateCategoryFilter: [],
  previousStateCategoryFilterShortName: [],
  updatePreviousCategoryFilter: (categoryFilter) => {},
  previousStateSelectedFilter: {},
  updatePreviousSelectedFilter: (filterName, filterShortname, filtersAll) => {},
  previousStateFilterHeight: null,
  updatePreviousFilterHeight: (filterHeight) => {},
  pageRedirection: null,
  updatePageRedirection: () => {},
  previousStatePageNumber: null,
  updatePreviousPageNumber: (pageNumber) => {},
  previousStateUseridFilter: [],
  previousStateUseridFilterShortName: [],
  updatePreviousUseridFilter: (userIdFilter) => {},
  previousStateStatusFilter: [],
  previousStateStatusFilterShortName: [],
  updatePreviousStatusFilter: (statusFilter) => {},
  previousStateSortFilter: [],
  previousStateSortFilterShortName: [],
  updatePreviousSortFilter: (sortFilter) => {},
  previousStateScrollSearchResults: null,
  updatePreviousScrollSearchResults: () => {},
  currentStatePageName: null,
  updateCurrentStatePageName: () => {},
  editorPageStateData: {},
  updateEditorPageData: (pageName, selectedData, sourceLoc, targetLoc) => {},
  initialStateFilterFlag: null,
  updateInitialFilterFlag: () => {},
  filterDisplayFlag: null,
  updateFilterDisplayFlag: () => {},
  checkInitState: null,
  updateCheckInitState: () => {},
  resetSearchPageData: () => {},
  resetDashboardPageData: () => {},
  navigateEditToSearchPageFlag: null,
  updateNavigateEditToSearchPageFlag: () => {},
  enableBannerMessage: null,
  updateEnableBannerMessage: () => {},
  languageCodeDropDwn: null,
  updateLanguageCodeDropDwn: () => {},
  filtersScrollPos: null,
  updateFiltersScrollPos: (filterName, scrollPos) => {},
  segmentsDetails: null,
  updateSegmentsDetails: (segDetails) => {},
  matchesSuggestionsCount: null,
  updateMatchesSuggestionsCount: (matchesCount) => {},
  changeMatchesDetails: null,
  updateChangeMatchesDetails: (changeMatchDetailFlag) => {},
  editorBottomLeftHeader: null,
  updateEditorBottomLeftHeader: (headerName) => {},
  editorBottomRightHeader: null,
  updateEditorBottomRightHeader: (headerName) => {},
  concordanceRetrieveData: null,
  updateConcordanceRetrieveData: (concData) => {},
  matchesRetrieveData: null,
  updateMatchesRetrieveData: () => {},
  editorSearchPageLocationData: null,
  updateEditorSearchPageLocationData: (page, selectedtext, sourceLocale, targetLocale) => {},
  intialfiltervalue: null,
  updateintialfiltervalue: () => {},
  enablesearchbuttoninfilter: null,
  updateEnableSearchbuttoninfilter: () => {},
  isUseSuggestionClicked: null,
  updateUseSuggestionClicked: () => {},
  selectedSuggestionMatchesDetails: null,
  updateSelectedSuggestionMatchesDetails: () => {},
  currentTargetLocale: null,
  updateCurrentTargetLocale: () => {},
  currentStartPageScroll: null,
  updateCurrentStartPageScroll: () => {},
  startpageDueinDataloaded: [],
  startpageDueinData: [],
  updateStartpageDueinData: () => {},
  startpageDueindataCountloaded: [],
  startpageDueindataCount: null,
  updateStartpageDueinDataCount: () => {},
  startpageDueinFilterCountloaded: [],
  startpageDueinFilterCount: null,
  updateStartpageDueinFilterCount: () => {},
  startpageGraphdataloaded: [],
  startpageGraphdata: [],
  updateStartpageGraphdata: () => {},
  startpagePendingtranslationsloaded: [],
  startpagePendingtranslations: [],
  updateStartpagePendingtranslations: () => {},
  selectedPopuplangvalue: null,
  updateselectedPopuplangvalue: () => {},
  intialloading: null,
  updateintialloading: () => {},
  editorPageRender: false,
  updateEditorPageRender: () => {},
  editorResizeMousePos: 0,
  updateEditorResizeMousePos: () => {},
  autoSaveHeaderChange: null,
  updateAutoSaveHeaderChange: () => {},
  editorResizeObj: null,
  updateEditorResizeObj: () => {},
  startPagepreviousStateObjectFilter: [],
  startPagepreviousStateObjectFilterShortName: [],
  startPageupdatePreviousObjectFilter: (objFilters) => {},
  startPagepreviousStateStatusFilter: [],
  startPagepreviousStateStatusFilterShortName: [],
  startPageupdatePreviousStatusFilter: (statusFilters) => {},
  startPagepreviousStateSortFilter: [],
  startPagepreviousStateSortFilterShortName: [],
  startPageupdatePreviousSortFilter: (sortFilters) => {},
  isConnectImageClicked: null,
  updateIsConnectImageClicked: () => {},
  editorHorizontalDragData: null,
  updateEditorHorizontalDragData: () => {},
  connectedProdData: null,
  updateConnectedProdResData: () => {},
  autoSaveEnabled: null,
  updateAutoSaveEnabled: () => {},
  editorBottomRotateHeader: null,
  updateEditorBottomRotateHeader: () => {},
  editorPageStatusButton: null,
  updateEditorPageStatusButton: () => {},
  contextMenuPostion: null,
  updateContextMenuPosition: () => {},
  contextMenuIconClicked: null,
  updateContextMenuIconClicked: () => {},
  selectedMatchesSuggestion: null,
  updateMatchesSuggestion: () => {},
  contextMenuOutsideClicked: null,
  updateContextMenuOutsideClicked: () => {},
  selectedLocaleName: null,
  updateSelectedLocaleName: () => {},
  bannerVisible: null,
  updateBannerVisible: () => {},
  tabNavigation: null,
  updateTabNavigation: () => {},
  numberOfReleasedTexts: null,
  updateNumberOfReleasedTexts: () => {},
  toastMessage: null,
  updateToastMessage: () => {},
  selectedtextsforbulkedit: null,
  updateSelectedtextsforbulkedit: () => {},
  bulkEditbuttonStatus: null,
  updateBulkEditbuttonStatus: () => {},
  editorBottomSectionHeight: null,
  updateEditorBottomSectionHeight: () => {},
  editorBottomLeftWidth: null,
  updateEditorBottomLeftWidth: () => {},
  editorBottomRightWidth: null,
  updateEditorBottomRightWidth: () => {},
  connectedProdRender: null,
  updateConnectedProdRender: () => {},
  shortcutKeyForConcordance: null,
  updateshortcutKeyForConcordance: () => {},
  dataOfRelaseTexts: null,
  updateDataOfRelaseTexts: () => {},
  showHistory: null,
  historyDetails: null,
  historyTabCount: null,
  updateHistoryTabData: () => {},
  actionToStatusMapping: null,
  updateActionToStatusMapping: () => {},
  paragraphMismatchErrorEditor: null,
  updateParagraphMismatchErrorEditor: () => {},
  textPartEmptyErrorEditor: null,
  updateTextpartEmptyErrorEditor: () => {},
  numberOfItemsAffected: null,
  updateNumberOfItemsAffected: () => {},
  resetValidationError: () => {},
  historyRestoreButtonClicked: null,
  updateHistoryRestoreButtonClicked: () => {},
  filterValues: null,
  updateFilterValues: () => {},
});

export default SitsContext;
