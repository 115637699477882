/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, {
  useContext, useState, useEffect,
} from 'react';
import Cookies from 'js-cookie';
import './EditorBottomLeftContainer.scss';
import EditorBottomLeftHeader from '../editorBottomLeftHeader/EditorBottomLeftHeader';
import Matches from '../matches/Matches';
import Concordance from '../concordance/Concordance';
import SitsContext from '../../../../context/SitsContext';

let thresholdWidthScrollSlider = 0;

function EditorBottomLeftContainer(props) {
  const sitsContext = useContext(SitsContext);
  const [editorLeftBottomWidth, setEditorLeftBottomWidth] = useState(0);
  const [changeTabOnNoResults, setChangeTabOnNoResults] = useState(null);
  const [scrollSliderConnectedProd, setScrollSliderConnectedProd] = useState(false);

  useEffect(() => {
    // Below logic is specific to connected products section
    const scrollElement = document.getElementsByClassName('scroll-indicator');
    if (scrollElement.length > 0) {
      setScrollSliderConnectedProd(true);
    } else if (scrollElement.length === 0) {
      setScrollSliderConnectedProd(false);
    }
  }, []);

  useEffect(() => {
    // This useeffect as of now won't be executed since we are not updateing the editor left width
    // in the store value
    const bottomLeftSectionElement = document.getElementById('editorBottomLeft');
    if ((sitsContext.editorHorizontalDragData.editorLeftWidth !== 0)
    && (sitsContext.editorHorizontalDragData.editorLeftWidth
  !== bottomLeftSectionElement.offsetWidth)) {
      bottomLeftSectionElement.style.width = `${sitsContext.editorHorizontalDragData.editorLeftWidth}px`;
    }
  }, [sitsContext.editorHorizontalDragData.editorLeftWidth]);

  // When there are no matches, we show link to concordance in the matches section
  // Below function make the changeTabOnNoResults flag as true when concordance is clicked in the
  // matches section. So that we change the header highlight to concordance from matches using
  // this flag
  const updateChangeToConcordanceTabHandler = (tabOnNoResults) => {
    setChangeTabOnNoResults(tabOnNoResults);
  };

  // Editor Left Section mouse handler functions

  const onMouseDownEditorLeftHandler = (e) => {
    const bottomLeftSectionElement = document.getElementById('editorBottomLeft');
    const viewportHeight = window.innerHeight; // Whole page height
    const bottomSectionElement = document.getElementById('editorBottomSection');
    const heightThresholdValue = (10 * (bottomSectionElement.offsetHeight)) / 100;
    const topSectionHeight = heightThresholdValue
    + ((viewportHeight - (bottomSectionElement.offsetHeight)));
    const thresholdValue = (10 * bottomLeftSectionElement.offsetWidth) / 100;
    const remainingWidth = bottomLeftSectionElement.offsetWidth - thresholdValue;
    if (topSectionHeight < e.clientY) {
      if (sitsContext.editorResizeObj.resizeFlag === false) {
        if ((remainingWidth) < (e.clientX)) {
          const horData = {
            mousePosHorizontal: e.clientX,
            resizeFlag: true,
          };
          sitsContext.updateEditorHorizontalDragData('multiplePropertyChange', horData);
        } else if ((remainingWidth) > (e.clientX)) {
          sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
        }
      }
    } else if (topSectionHeight > e.clientY) {
      sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
    }
  };

  const onMouseUpEditorLeftHandler = () => {
    sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
  };

  useEffect(() => {
    if (sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === true) {
      const bottomLeftSectionElement = document.getElementById('editorBottomLeft');
      const bottomRightSectionElement = document.getElementById('editorBottomRight');
      const headerElement = document.getElementById('editorBottomLeftHeaderSection');
      const headerElementValues = headerElement.getBoundingClientRect();
      const bottomRightWidth = window.innerWidth - (headerElementValues.right);
      const bottomLeftWidth = headerElementValues.right;
      setEditorLeftBottomWidth(bottomLeftWidth);
      Cookies.set('editorBottomLeftWidth', bottomLeftWidth);
      sitsContext.updateEditorBottomRightWidth(bottomRightWidth);
      Cookies.set('editorBottomRightWidth', bottomRightWidth);
      bottomLeftSectionElement.style.width = `${bottomLeftWidth}px`;
      bottomRightSectionElement.style.width = `${bottomRightWidth}px`;
      bottomRightSectionElement.style.cursor = 'default';
      bottomLeftSectionElement.style.cursor = 'default';
      sitsContext.updateEditorHorizontalDragData('resizeFlag', false);
    }
  }, [sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate]);

  const onMouseOverEditorLeftHandler = (e) => {
    let remainingWidth;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight; // Whole page height
    const bottomSectionElement = document.getElementById('editorBottomSection');
    const heightThresholdValue = (5 * (bottomSectionElement.offsetHeight)) / 100;
    const topSectionHeight = heightThresholdValue
    + ((viewportHeight - (bottomSectionElement.offsetHeight)));
    const bottomLeftSectionElement = document.getElementById('editorBottomLeft');
    const bottomRightSectionElement = document.getElementById('editorBottomRight');
    const calcThresholdWidth = (85 * (window.innerWidth)) / 100;

    if (sitsContext.editorBottomRightWidth < calcThresholdWidth) {
      if (sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === true) {
        Cookies.set('editorLeftSectionRotated', false);
        sitsContext.updateEditorBottomRotateHeader('editorLeftHeaderRotate', false);
      }
    } else if (sitsContext.editorBottomRightWidth > calcThresholdWidth) {
      if (sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === false) {
        Cookies.set('editorLeftSectionRotated', true);
        sitsContext.updateEditorBottomRotateHeader('editorLeftHeaderRotate', true);
        return;
      }
    }
    if (topSectionHeight < e.clientY) {
      if (sitsContext.editorHorizontalDragData.resizeFlag === true) {
        const thresholdValue = (15 * bottomLeftSectionElement.offsetWidth) / 100;
        remainingWidth = bottomLeftSectionElement.offsetWidth - thresholdValue;
      } else {
        const thresholdValue = (5 * bottomLeftSectionElement.offsetWidth) / 100;
        remainingWidth = bottomLeftSectionElement.offsetWidth - thresholdValue;
      }
      if (sitsContext.editorResizeObj.resizeFlag === false) {
        if ((remainingWidth) < (e.clientX)) {
          bottomLeftSectionElement.style.cursor = 'ew-resize';
        } else if ((remainingWidth) > (e.clientX)) {
          bottomLeftSectionElement.style.cursor = 'default';
        }
      }

      if (sitsContext.editorHorizontalDragData.resizeFlag === true
      && (sitsContext.editorResizeObj.resizeFlag === false)) {
        const scrollElement = document.getElementsByClassName('scroll-indicator');
        const dx = (sitsContext.editorHorizontalDragData.mousePosHorizontal) - e.clientX;
        sitsContext.updateEditorHorizontalDragData('mousePosHorizontal', e.clientX);
        const bottomLeftWidth = parseInt(getComputedStyle(bottomLeftSectionElement, '').width, 10) - dx;
        setEditorLeftBottomWidth(bottomLeftWidth);
        Cookies.set('editorBottomLeftWidth', bottomLeftWidth);
        bottomLeftSectionElement.style.width = `${bottomLeftWidth}px`;
        const bottomRightWidth = viewportWidth - bottomLeftWidth;
        sitsContext.updateEditorBottomRightWidth(bottomRightWidth);
        Cookies.set('editorBottomRightWidth', bottomRightWidth);
        bottomRightSectionElement.style.width = `${bottomRightWidth}px`;
        if (scrollSliderConnectedProd === true) {
          if (thresholdWidthScrollSlider === 0) {
            if (scrollElement.length === 0) {
              thresholdWidthScrollSlider = bottomRightWidth;
            }
          } else if ((thresholdWidthScrollSlider !== 0)
          && (thresholdWidthScrollSlider > bottomRightWidth)) {
            sitsContext.updateConnectedProdRender(!sitsContext.connectedProdRender);
            thresholdWidthScrollSlider = 0;
          }
        }
      }
    } else if (topSectionHeight > e.clientY) {
      bottomLeftSectionElement.style.cursor = 'default';
    }
  };

  return (
    <div
      id="editorBottomLeft"
      className="bottom_left_child"
      onMouseMove={(e) => onMouseOverEditorLeftHandler(e)}
      onMouseUp={onMouseUpEditorLeftHandler}
      onMouseDown={(e) => onMouseDownEditorLeftHandler(e)}
    >
      <EditorBottomLeftHeader
        selectedSegment={props.segmentSelected}
        tabChangeOnNoResults={changeTabOnNoResults}
        tabChangeHandler={updateChangeToConcordanceTabHandler}
      />
      {(sitsContext.editorBottomLeftHeader === 'matches'
          && sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === false
          && props.segmentSelected.initialRendering === false) && (
          <Matches
            selectedSegment={props.segmentSelected}
            tabChangeHandler={updateChangeToConcordanceTabHandler}
            editorLeftWidth={editorLeftBottomWidth}
          />
      )}
      {(sitsContext.editorBottomLeftHeader === 'concordance'
          && sitsContext.editorBottomRotateHeader.editorLeftHeaderRotate === false) && (
          <Concordance
            confirmButtonClicked={props.segmentSelected.confirmButtonClicked}
            editorLeftWidth={editorLeftBottomWidth}
          />
      )}
    </div>
  );
}

export default EditorBottomLeftContainer;
