/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {
  useState, useEffect, useRef, useContext, useLayoutEffect, useCallback,
} from 'react';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/focus/style.scss';
import './Contextmenu.scss';
import '@ingka/list-view/style.scss';
import '@ingka/list-box/style.scss';
import ListBox from '@ingka/list-box';
import ListView, { ListViewItem } from '@ingka/list-view';
import ContextmenuValue from './contextmenuValue.json';
import SitsContext from '../../../context/SitsContext';

const disabledTrue = true;
const disabledFalse = false;

export default function Contextmenu() {
  const sitsContext = useContext(SitsContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const ref = useRef();

  useLayoutEffect(() => {
    if (isModalOpen === true) {
      if ((sitsContext.contextMenuPostion.topPositionValue !== null)
      && (sitsContext.contextMenuPostion.leftPositionValue !== null)
      && sitsContext.contextMenuPostion.rightPositionValue !== null) {
        const elementHeight = document.getElementById('contextMenuContainer');
        if (elementHeight !== undefined) {
          const calculateThresholdValue = sitsContext.contextMenuPostion.topPositionValue + elementHeight.offsetHeight;
          if (((sitsContext.contextMenuPostion.bottomPositionValue + elementHeight.offsetHeight) >= window.innerHeight)) {
            elementHeight.style.top = `${sitsContext.contextMenuPostion.topPositionValue - elementHeight.offsetHeight - 15}px`;
          } else {
            elementHeight.style.top = `${sitsContext.contextMenuPostion.topPositionValue}px`;
          }
          if (sitsContext.contextMenuPostion.leftPositionValue > elementHeight.offsetWidth) {
            elementHeight.style.left = `${sitsContext.contextMenuPostion.leftPositionValue - elementHeight.offsetWidth}px`;
          } else {
            elementHeight.style.left = `${sitsContext.contextMenuPostion.rightPositionValue}px`;
          }
        }
      }
    }
  }, [sitsContext.contextMenuPostion, isModalOpen]);

  const listItemOnClickHandler = (selectedMenuvalue) => {
    if (selectedMenuvalue === 'Use suggestion') {
      sitsContext.updateUseSuggestionClicked(true);
      sitsContext.updateSelectedSuggestionMatchesDetails(sitsContext.selectedMatchesSuggestion);
    }
  };

  useEffect(() => {
    setModalOpen(sitsContext.contextMenuIconClicked);
  }, [sitsContext.contextMenuIconClicked]);

  return (
    <div style={{ display: isModalOpen ? '' : 'none' }} className="context_menu_container" ref={ref}>
      <ListBox
        className="Context_Menu"
        open={isModalOpen}
        id="contextMenuContainer"
      >
        <ListView
          id="Context_Menu_list"
          showDivider={false}
          size="small"
        >
          {ContextmenuValue.map((menuvalue) => (
            <ListViewItem
              control="link"
              key={menuvalue.menu_index}
              onClickCapture={() => {
                listItemOnClickHandler(menuvalue.menu_value);
              }}
              controlProps={{
                value: menuvalue.menu_value,
              }}
              id={menuvalue.menu_index}
              inset
              name={menuvalue.menu_value}
              title={menuvalue.menu_value}
              disabled={menuvalue.menu_value !== 'Use suggestion'}
            />
          ))}
        </ListView>
      </ListBox>
    </div>
  );
}
