/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-concat */
/* eslint-disable no-import-assign */
/* eslint-disable func-names */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useContext } from 'react';
import Search from '@ingka/search';
import SSRIcon from '@ingka/ssr-icon';
import crossSmall from '@ingka/ssr-icon/paths/cross-small';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/search/style.scss';
import '@ingka/focus/style.scss';
import './SearchBarComponent.scss';
import { selectedPopupValue } from '../../../App';
import { itemNumberFormat, itemNumber } from '../../../constants';
import SitsContext from '../../../context/SitsContext';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';

export let SearchbarData = []; // Inatializing array to capture the searchbar data

export let itemNumberCheck = '';
let localeObj = {};
// Display custom clear icon when comes to search page from different page
let displayCustomIcon = false;

export default function SearchBarComponent(props) {
  const sitsCtx = useContext(SitsContext);
  const [trimSpace, setTrimSpace] = useState(false);
  const [text, setText] = useState(); // intializing the text to capture the inout text value
  const [previoustext, setPrevioustext] = useState();
  // intializing the previous text to captue the previous searched text
  const [selectedlang, setSelectedlang] = useState();
  // intializing the selectedlang to capture the selected lang_code in searchbar
  const [directionLang, setDirectionLang] = useState('ltr'); // intializing the directionlang to capture the rtl langueges
  // eslint-disable-next-line no-unused-vars
  const [defaultValue, setDefaultValue] = useState();

  // functionality to make direction lang as ltr when there is a change in target locale
  useEffect(() => {
    localeObj = {
      EN: 'en-zz',
      [selectedPopupValue.selectedlang]: [
        selectedPopupValue.selectedlangvalue,
      ],
    };
    setDirectionLang('ltr');
  }, [selectedPopupValue]);

  const handleWindowSizeChangeHandler = () => {
    const searchBar = document.getElementById('searchbar');
    const customSearchIcon = document.getElementById('searchBarCustomClearIcon');
    const searchBarContainer = document.getElementById('searchbarContainer');
    if (searchBar !== undefined && customSearchIcon !== undefined) {
      const searchBarPositionDetails = searchBar.getBoundingClientRect();
      searchBar.style.paddingInlineEnd = `${searchBarContainer.offsetHeight - 2}px`;
      customSearchIcon.style.height = `${searchBarContainer.offsetHeight - 2}px`;
      customSearchIcon.style.width = `${searchBarContainer.offsetHeight - 2}px`;
      customSearchIcon.style.left = `${searchBarPositionDetails.right - 40}px`;
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChangeHandler);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChangeHandler);
    };
  }, []);

  useEffect(() => {
    const searchBar = document.getElementById('searchbar');
    if ((sitsCtx.previousSearchData.search_value !== null)
    && (sitsCtx.previousSearchData.search_value !== undefined)) {
      displayCustomIcon = true;
      // searchBar.focus();
      // searchBar.value = sitsCtx.previousSearchData.search_value;
      setDefaultValue(sitsCtx.previousSearchData.search_value);
      // const searchBar = document.getElementById('searchbar');
      const customSearchIcon = document.getElementById('searchBarCustomClearIcon');
      const searchBarContainer = document.getElementById('searchbarContainer');
      if (searchBar !== undefined && customSearchIcon !== undefined) {
        const searchBarPositionDetails = searchBar.getBoundingClientRect();
        searchBar.style.paddingInlineEnd = `${searchBarContainer.offsetHeight - 2}px`;
        customSearchIcon.style.height = `${searchBarContainer.offsetHeight - 2}px`;
        customSearchIcon.style.width = `${searchBarContainer.offsetHeight - 2}px`;
        customSearchIcon.style.left = `${searchBarPositionDetails.right - 40}px`;
      }
      // const event = new Event('input', {
      //   bubbles: true,
      //   cancelable: true,
      //   value: '0.2',
      // });
      // searchBar.dispatchEvent(event);
    }

    if ((sitsCtx.languageCodeDropDwn === 'ar-SA') || (sitsCtx.languageCodeDropDwn === 'he-IL')) {
      setDirectionLang('rtl');
    }
    if (sitsCtx.languageCodeDropDwn === 'en-zz') {
      localeObj = {
        EN: 'en-zz',
        [selectedPopupValue.selectedlang]: [
          selectedPopupValue.selectedlangvalue,
        ],
      };
    } else {
      localeObj = {
        [selectedPopupValue.selectedlang]: [
          selectedPopupValue.selectedlangvalue,
        ],
        EN: 'en-zz',
      };
    }
    setTimeout(() => {
      searchBar.focus();
      if ((sitsCtx.previousSearchData.search_value !== null)
      && sitsCtx.previousSearchData.search_value.length >= 1) {
        searchBar.setSelectionRange(
          sitsCtx.previousSearchData.search_value.length,
          sitsCtx.previousSearchData.search_value.length,
        );
      }
    }, 500);
  }, []);

  // functionality to capture the search action in the filters section
  useEffect(() => {
    if (
      SearchbarData.search_value === ''
      || SearchbarData.search_value === null
      || SearchbarData.search_value === undefined
    ) {
      searchdata(null, false, null);
    }
  }, [props.filterssearch]);

  // grouping the searchbar data and passing the data as props
  const searchdata = (dropdowndata, searchicon, searchtext) => {
    SearchbarData = {
      searchclick: searchicon,
      search_value: searchtext,
      selected_locale_code: dropdowndata,
    };
    props.Searchdata(SearchbarData);
  };

  const trimExtraSpacesOnPaste = () => {
    setTrimSpace(true);
  };

  // functionality to change the search bar direction for rtl_languages
  const changeDirection = (e) => {
    const languageCode = e.target.value.toString();
    sitsCtx.updateLanguageCodeDropDwn(languageCode);
    const langCode = languageCode.slice(0, 2);
    if (langCode === 'ar' || langCode === 'he') {
      setDirectionLang('rtl');
    } else {
      setDirectionLang('ltr');
    }
  };

  // passing the clear grid value as props
  const clearGrid = () => {
    const display = true;
    props.ShowResultsGrid(display);
  };

  const clearFieldHandler = () => {
    setDefaultValue(null);
    displayCustomIcon = false;
    props.searchBarOnClear();
    props.countOnClearHandler();
    clearGrid();
    searchdata(null, false, null);
    setText(); // input value is updated with empty value
    setPrevioustext(); // previous value is updated with empty value
  };

  const inputTextchange = () => {
    if (trimSpace === true) {
      document.getElementById('searchbar').value = document.getElementById('searchbar').value.replace(/^\s*|\s*$/g, '');
      setTrimSpace(false);
    }
  };

  return (
    <div className="search_bar" id="searchbarContainer">
      <Search
        onPaste={() => trimExtraSpacesOnPaste()}
        onChange={inputTextchange}
        actions={null}
        ariaLabel={lablesAndnames.searchPage_searchBarLabel}
        clearBtnText={lablesAndnames.searchPage_clearButtonText}
        id="searchbar"
        placeholder={lablesAndnames.searchPage_searchBarPlaceholder}
        scopeSelectProps={{ onChange: changeDirection }}
        dir={directionLang}
        className={directionLang === 'rtl' ? 'search-rtl' : null}
        defaultValue={(defaultValue !== null && defaultValue !== undefined) ? defaultValue : ''}
        onInput={function (e) {
          displayCustomIcon = false;
          if (e.target.value === '') {
            setText();
            searchdata(null, true, null);
          } else if (e.target.value !== undefined) {
            if ((itemNumber.test((e.target.value).trim())
            || itemNumberFormat.test((e.target.value).trim()))) {
              itemNumberCheck = true;
              searchdata(null, false, e.target.value.trim());
              setText(e.target.value.trim());
            } else {
              itemNumberCheck = false;
              searchdata(null, false, e.target.value.trim());
              setText(e.target.value.trim());
            }
          }
        }} // onInput functionality to capture the entered text
        onSearch={function OnClick(e, data) {
          if (
            text !== undefined
            || (text === previoustext && selectedlang !== data.scope)
          ) {
            if (text.length !== 0) {
              const searchicon = true;
              searchdata(data.scope, searchicon, text);
              setSelectedlang(data.scope);
              // selectedlang is updated with the selected lang_code in the dropdown
              setPrevioustext(text); // Previous text is updated with the searched text
            }
          }
        }} // Functionality for the search action
        onClear={function OnClick() {
          props.searchBarOnClear();
          props.countOnClearHandler();
          clearGrid();
          searchdata(null, false, null);
          setText(); // input value is updated with empty value
          setPrevioustext(); // previous value is updated with empty value
        }} // functionality for onclear function
        scope={localeObj}
        submitBtnText={lablesAndnames.searchPage_submitButtonText}
      />
      <div style={{ display: displayCustomIcon ? 'flex' : 'none' }} onClick={clearFieldHandler} className="searchBar-crossIcon" id="searchBarCustomClearIcon">
        <SSRIcon paths={crossSmall} className="custom-clear-icon" />
      </div>
    </div>
  );
}
