/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useContext } from 'react';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/modal/dist/style.css';
import Modal, {
  Sheets, ModalBody, ModalHeader, ModalFooter,
} from '@ingka/modal';
import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import FormField from '@ingka/form-field';
import './Languagepopup.scss';
import { useCookies } from 'react-cookie';
import { retrieveUserdata } from '../../api/Apirequests';
import ErrorHandler from '../errorhandler/ErrorHandler';
import SitsContext from '../../context/SitsContext';
import lablesAndnames from '../../lablesAndnames/labelsForComponents.json';

export default function Languagepopup(props) {
  const sitsContext = useContext(SitsContext);
  const [valueSelected, setValueSelected] = useState();
  // intializing the value select to capture the selected value in dropdown
  // eslint-disable-next-line no-unused-vars
  const [selectedLocaleName, setSelectedLocaleName] = useState(null);
  const [selectedLocaleCode, setSelectedLocaleCode] = useState(null);
  const [accessLangCode, setAccessLangcode] = useState();
  // intializing accesslangcode to capture the list of languages
  const [cookies, setCookie] = useCookies(['user']); // intializing the cookies for storing the selected lang_code
  const [modalOpen, setModalOpen] = useState(); // intailizing the modalopen to display the  popup
  const [settings, setSettings] = useState(false);
  // intializing the settings to capture the click of settings icon
  const [validation, setValidation] = useState(false);
  // intializing the validation for error message validation
  const [defaultValue, setDefaultValue] = useState(true);
  // intializing the default value for capture the default value
  const [isContinueClicked, setIsContinueclicked] = useState(false);
  // intializing the continueclick to capture the click funtionality
  const [isErrorHandlerOpen, setErrorHandlerOpen] = useState(false);
  // intializing to enable the Error handling popup
  const [correlationId, setCorrelationId] = useState();
  // intializing to capture th correlation Id from userpreference api fail
  // eslint-disable-next-line no-unused-vars
  const [langvalue, setlangvalue] = useCookies(['selected_locale']);
  // eslint-disable-next-line no-unused-vars
  const [selectedLocaleNameFromCookie, setSelectedLocaleNameFromCookie] = useCookies(['selected_locale_name']);

  // Userpreference API request when there is a change in the userdetails
  useEffect(() => {
    getUserDataFromBackendHandler(props.userDetails);
  }, [props.userDetails]); // userdetails is captured from the authentication

  useEffect(() => {
    if (modalOpen === true) {
      if (langvalue.selected_locale !== undefined) {
        setValueSelected(langvalue.selected_locale);
      }
      document.querySelectorAll('#countryDropDownSettings option').forEach((opt) => {
        if (opt.value === 'Choose an option') {
          // eslint-disable-next-line no-param-reassign
          opt.disabled = true;
        }
      });
    }
  }, [modalOpen]);

  // modal open and settings states are updates when there is change in settings icon
  useEffect(() => {
    if (props.settingsIcon !== undefined) {
      setSettings(true);
      setModalOpen(true);
    }
  }, [props.settingsIcon]);

  // API call for the userprefernce data
  const getUserDataFromBackendHandler = (userdata) => {
    retrieveUserdata(userdata, setLanguageFromUserData);
  };

  // API output response is assigned to accesslangcodes array
  const setLanguageFromUserData = (data) => {
    if (data[0].getStatus !== undefined && data[0].getStatus !== null
      && data[0].getStatus.length !== 0) {
      const statusMappingStoreLocalData = {};
      data[0].getStatus.forEach((statusMapData) => {
        statusMappingStoreLocalData[statusMapData.action] = statusMapData.status;
      });
      sitsContext.updateActionToStatusMapping(statusMappingStoreLocalData);
    }
    if (typeof data[0].autoSaveEnabled !== 'undefined') {
      sitsContext.updateAutoSaveEnabled(data[0].autoSaveEnabled);
    }
    if (data.status !== 200 && data.status >= 400 && props.settingsIcon === undefined) {
      setErrorHandlerOpen(true);
      setCorrelationId(data.data.CorrelationID);
    }
    if (data.length === 0) {
      const defaultLangValue = 'EN-ZZ';
      passLanguageValueHandler(defaultLangValue);
    } // If array data is null , default value  is assigned
    if (data.length !== 0) {
      if (data[0].locales.length !== 0) {
        setAccessLangcode(data[0].locales);
        sitsContext.updateSelectedLocaleName(data[0].locales[0].lang_crty_name);
        // if cookie value is empty  , the modal is displayed to select the value
        if (cookies.selected_locale !== undefined) {
          setModalOpen(false);
          passLanguageValueHandler(cookies.selected_locale);
          sitsContext.updateSelectedLocaleName(cookies.selected_locale_Name);
        } else { setModalOpen(true); } // The value is retrieved from the cookie
      }
      if (data[0].locales.length === 1) {
        setDefaultValue(false);
        setValueSelected(data[0].locales[0].locale_code);
        setCookie('selected_locale', data[0].locales[0].locale_code); // selected value is stored in cookie
        setCookie('selected_locale_Name', data[0].locales[0].lang_crty_name);
        passLanguageValueHandler(data[0].locales[0].locale_code);
      }
    } // If the array contains one lang_code
  };

  // Continue button Funtionality
  const onClickContinueHandler = () => {
    if (selectedLocaleName !== null) {
      sitsContext.updateToastMessage(true, `${selectedLocaleName} loaded successfully!`);
      setCookie('selected_locale_Name', selectedLocaleName);
    } else if (selectedLocaleName === null) {
      if (sitsContext.selectedLocaleName === selectedLocaleNameFromCookie) {
        sitsContext.updateToastMessage(true, `${selectedLocaleNameFromCookie} loaded successfully!`);
      }
    }
    sitsContext.updateSelectedtextsforbulkedit([]);
    setIsContinueclicked(true);
    setValueSelected(selectedLocaleCode);
    setCookie('selected_locale', valueSelected); // selected value is stored in cookie
    if (valueSelected === null || valueSelected === undefined || valueSelected === 'Choose an option') {
      setValidation(true); // if the selected value is null the validation is set to true
    } else {
      setValidation(false);
      passLanguageValueHandler(valueSelected);
    }//  the selected value is passed to passlanguagevalue function
  };

  // To pass the selected value through the (child to parent)props method
  const passLanguageValueHandler = (value) => {
    if (valueSelected === 'Choose an option') {
      setModalOpen(true);
      // if the selected value is default value the  error validation will true
    } else (props.setLanguageHandler(value));
  };

  // handle change functionality
  const onChangeHandler = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    sitsContext.updateSelectedLocaleName(e.nativeEvent.target[index].text);
    setDefaultValue(false);
    // default variable is false because the value is selected from the dropdown
    setValidation(false); // error validation is false
    setSelectedLocaleCode(e.target.value);
    setValueSelected(e.target.value);
    // setCookie('selected_locale', e.target.value); // selected value is stored in cookie
    // setCookie('selected_locale_Name', e.nativeEvent.target[index].text);
    setSelectedLocaleName(e.nativeEvent.target[index].text);
    // document.getElementById('localeSelectButton').focus();
  };

  // functionality for the click outside the modal
  const onclickoutHandler = () => {
    // if (settings === true && isContinueClicked === true && validation === false) {
    //   setModalOpen(false);// modal is closed when click outside the modal
    // } else { setValidation(true); }
    if (modalOpen === true && isContinueClicked === true) {
      setModalOpen(false);
    } else { setValidation(true); }
  };

  if (accessLangCode !== undefined) {
    return (
      <div>
        <Modal
          style={{ display: modalOpen === true ? '' : 'none' }}
          focusLockProps={{ disabled: false }}
          handleCloseBtn={onclickoutHandler}
          visible
        >

          <Sheets
            className={settings === true ? '' : 'sheets1'}
            header={settings === true && <ModalHeader title="Settings" />}
            footer={<ModalFooter className="modal-button"><React.Fragment key=".0"><Button onClick={onClickContinueHandler} text={lablesAndnames.button_continueButtonText} type="primary" id="localeSelectButton" /></React.Fragment></ModalFooter>}
          >
            <div />

            <ModalBody>
              {settings === false && <h4>Select a Locale</h4>}
              <FormField
                shouldValidate={validation}
                validation={{ msg: 'Select a locale & Click Continue' }}
              >

                <Select
                  className={validation ? 'select-error' : null}
                  label={lablesAndnames.searchPage_languagePopupLabel}
                  type="text"
                  value={defaultValue === false ? valueSelected : cookies.selected_locale}
                  onChange={(event) => onChangeHandler(event)}
                  id="countryDropDownSettings"
                >
                  {accessLangCode.map((language, index) => (
                    <Option
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      name={language.lang_crty_name}
                      value={language.locale_code}
                    />
                  ))}
                </Select>
              </FormField>
            </ModalBody>
          </Sheets>
        </Modal>
      </div>
    );
  }
  if (accessLangCode === undefined && isErrorHandlerOpen === true) {
    return (
      <ErrorHandler errorDisplayed={3} correlationIdForUserData={correlationId} />);
  }
}
