/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect, useState } from 'react';
import './ToastMessage.scss';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/toast/style.scss';
import Toast from '@ingka/toast';
import SitsContext from '../../context/SitsContext';

let exitTransition = false;

export default function ToastMessage() {
  const sitsContext = useContext(SitsContext);
  const [exitTransitionAnimation, setExitTransition] = useState(false);

  useEffect(() => {
    if (sitsContext.toastMessage.showToastMessage === true) {
      setTimeout(() => {
        sitsContext.updateToastMessage(false, '');
      }, 5000);
      if (sitsContext.toastMessage.toastMessageDescription.includes('loaded successfully')) {
        setExitTransition(true);
        exitTransition = true;
      } else {
        setExitTransition(false);
        exitTransition = false;
      }
    } else if (sitsContext.toastMessage.showToastMessage === false) {
      // if (sitsContext.toastMessage.toastMessageDescription.includes('loaded successfully')) {
      setTimeout(() => {
        setExitTransition(false);
      }, 200);
      // setExitTransition(false);
      exitTransition = false;
      // }
    }
  }, [sitsContext.toastMessage]);

  const hideToastMessageHandler = () => {
    sitsContext.updateToastMessage(false, '');
  };

  // const transitionToastMessageHandler = () => {
  //   console.log(sitsContext.toastMessage.showToastMessage);
  //   sitsContext.updateToastMessage(true, 'Transition loaded successfully');
  // };

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div>
      {/* {
        sitsContext.toastMessage.showToastMessage === true && ( */}
      <Toast
        id="toastMessageContainer"
        className={sitsContext.toastMessage.showToastMessage
          ? (sitsContext.toastMessage.toastMessageDescription.includes('loaded successfully')
            ? 'toast-display-locale-start'
            : 'toast-display')
          : ((exitTransition && exitTransitionAnimation) && 'toast-display-locale-exit')}
        text={sitsContext.toastMessage.toastMessageDescription}
        onCloseRequest={hideToastMessageHandler}
        // onTransitionEnd={transitionToastMessageHandler}
        isOpen={sitsContext.toastMessage.showToastMessage}
      />
      {/* )
      } */}
    </div>
  );
}
