/* eslint-disable default-param-last */

export const updateGraphData = (data) => ({
  type: 'UPDATE_GRAPH_DATA',
  payload: data,
}); // Action for the GRAPH_DATA

export const updatePendingTranslationsData = (data) => ({
  type: 'UPDATE_PENDINGTRANSLATIONS_DATA',
  payload: data,
}); // Action for the PENDINGTRANSLATIONS

export const updateDueintableData = (data) => ({
  type: 'UPDATE_DUEINTABLE_DATA',
  payload: data,
}); // Action for the DUEINTABLE

const initialState = {
  graphData: [],
  pendingTranslationData: [],
  dueinTableData: [],
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_GRAPH_DATA':
      return {
        ...state,
        graphData: [...state.graphData, action.payload],
      };
    case 'UPDATE_PENDINGTRANSLATIONS_DATA':
      return {
        ...state,
        pendingTranslationData: [...state.pendingTranslationData, action.payload],
      };
    case 'UPDATE_DUEINTABLE_DATA':
      return {
        ...state,
        dueinTableData: [...state.dueinTableData, action.payload],
      };
    default:
      return state;
  }
};
