/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-const */
/* eslint-disable react/no-danger */
/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, {
  useContext, useState, useLayoutEffect, useRef, useEffect,
} from 'react';
import Search from '@ingka/search';
import SSRIcon from '@ingka/ssr-icon';
import crossSmall from '@ingka/ssr-icon/paths/cross-small';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/search/style.scss';
import '@ingka/focus/style.scss';
// import '../Matches/Matches.scss';
import FormField from '@ingka/form-field';
import CommercialMessage from '@ingka/commercial-message';
import './Concordance.scss';
import '@ingka/variables/style.scss';
import '../../../../App.scss';
import information from '@ingka/ssr-icon/paths/information-circle';
import rtlDirectionCountries from '../../../../countriesData/rtlCountries.json';
import { selectedPopupValue } from '../../../../App';
import { concordanceSearch } from '../../../../api/Apirequests';
import Loadingcomponent from '../../../../common-components/loader/Loadingcomponent';
import SitsContext from '../../../../context/SitsContext';
import lablesAndnames from '../../../../lablesAndnames/labelsForComponents.json';
import negativeFlowMsg from '../../../../messages/negativeFlowMessages.json';

// Display custom clear icon when comes to search page from different page
let displayCustomIcon = false;

const Concordance = (props) => {
  const [searchValue, setSearchValue] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isResultsEmpty, setIsResultsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingText, setLoadingText] = useState('Loading your results');
  // eslint-disable-next-line no-unused-vars
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [displayingResults, setIsDisplayingResults] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [clearedResults, setClearedResults] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [trimSpace, setTrimSpace] = useState(false);
  const [handleLesserWidth, setHandleLesserWidth] = useState(false);
  const [rtlLang, setRtlLang] = useState(false);
  const sitsContext = useContext(SitsContext);
  const inputRef = useRef();

  useEffect(() => {
    if (props.editorBottomLayoutHeight !== 0) {
      const searchBarInputFieldElement = document.getElementById('searchbar_concordance');
      const customSearchIcon = document.getElementById('concordSearchBarCustomClearIcon');
      if (customSearchIcon !== undefined) {
        const searchBarPositionDetails = searchBarInputFieldElement.getBoundingClientRect();
        customSearchIcon.style.left = `${searchBarPositionDetails.right - 40 - 48}px`;
      }
    }
  }, [sitsContext.editorBottomSectionHeight]);

  const concordanceResultsHandler = (response) => {
    if (response.data.length === 0) {
      setIsResultsEmpty(true);
      sitsContext.updateConcordanceRetrieveData('noResults', true);
    } else if (response.data.length !== 0) {
      setIsResultsEmpty(false);
      sitsContext.updateConcordanceRetrieveData('noResults', false);
    }
    // setSearchResults((previousSearchResults) => [...previousSearchResults, response.data]);
    setIsDisplayingResults(true);
    setSearchResults(response.data);
    sitsContext.updateConcordanceRetrieveData('searchedResults', response.data);
    // setSizeOfTextfieldHandler(response.data);
    setIsLoading(false);
  };

  const trimExtraSpacesOnPaste = () => {
    setTrimSpace(true);
  };

  const searchSubmitHandler = () => {
    if (searchValue !== null && searchValue.length !== 0) {
      setIsLoading(true);
      setIsSearchClicked(true);
      const reqPayload = {
        text: searchValue,
        localeCode: selectedPopupValue.selectedlangvalue,
        userID: sessionStorage.getItem('userId'),
        isParentEnabled: false,
      };
      concordanceSearch(reqPayload, concordanceResultsHandler);
    }
  };

  const getResultsHandler = (searchText) => {
    setIsLoading(true);
    setIsSearchClicked(true);
    const reqPayload = {
      text: searchText,
      localeCode: selectedPopupValue.selectedlangvalue,
      userID: sessionStorage.getItem('userId'),
      isParentEnabled: false,
    };
    concordanceSearch(reqPayload, concordanceResultsHandler);
  };

  useLayoutEffect(() => {
    const concordanceSearchBarElement = document.getElementById('concordanceContainer');
    const concordanceTableElement = document.getElementById('concordanceTable');
    const searchBarElement = document.getElementById('searchbar_concordance');
    const calcMaxWidth = (85 * (window.innerWidth)) / 100;
    if (concordanceSearchBarElement !== undefined) {
      concordanceSearchBarElement.style.maxWidth = (calcMaxWidth) + 'px';
    }
    if (concordanceTableElement !== undefined) {
      concordanceTableElement.style.maxWidth = (calcMaxWidth) + 'px';
    }
    if (sitsContext.concordanceRetrieveData.noResults === true) {
      setIsResultsEmpty(true);
    }
    const selectedTargetLang = selectedPopupValue.selectedlang;
    if (rtlDirectionCountries.includes(selectedTargetLang.toUpperCase())) {
      setRtlLang(true);
    } else if (rtlLang === true) {
      setRtlLang(false);
    }
    if (sitsContext.concordanceRetrieveData.searchedText !== null
        && sitsContext.concordanceRetrieveData.searchedText !== '') {
      setSearchValue(sitsContext.concordanceRetrieveData.searchedText);
      setDefaultValue(sitsContext.concordanceRetrieveData.searchedText);
      displayCustomIcon = true;
      const customSearchIcon = document.getElementById('concordSearchBarCustomClearIcon');
      if (searchBarElement !== undefined && customSearchIcon !== undefined) {
        const searchBarPositionDetails = searchBarElement.getBoundingClientRect();
        searchBarElement.style.paddingInlineEnd = `${concordanceSearchBarElement.offsetHeight - 2}px`;
        customSearchIcon.style.height = `${concordanceSearchBarElement.offsetHeight - 4}px`;
        customSearchIcon.style.width = `${concordanceSearchBarElement.offsetHeight - 2}px`;
        customSearchIcon.style.left = `${searchBarPositionDetails.right - 40 - 48}px`;
      }
    } else if (displayCustomIcon === true) {
      displayCustomIcon = false;
    }
    if (sitsContext.concordanceRetrieveData.noMatchesIndicator === true) {
      fnCallForGettingResults();
    } else if (sitsContext.concordanceRetrieveData.noMatchesIndicator === false) {
      if (sitsContext.concordanceRetrieveData.searchedText
      && sitsContext.concordanceRetrieveData.searchedText.length !== 0) {
        setIsDisplayingResults(true);
        setIsSearchClicked(true);
        setSearchResults(sitsContext.concordanceRetrieveData.searchedResults);
        setTimeout(() => {
          const el = document.getElementById('concordanceTable');
          el.scrollTo(0, sitsContext.concordanceRetrieveData.scrollPosition);
        }, 100);
      }
    }
    setTimeout(() => {
      searchBarElement.focus();
      if ((sitsContext.concordanceRetrieveData.searchedText !== null)
    && (sitsContext.concordanceRetrieveData.searchedText !== '')
    && sitsContext.concordanceRetrieveData.searchedText.length >= 1) {
        // eslint-disable-next-line max-len
        searchBarElement.setSelectionRange(sitsContext.concordanceRetrieveData.searchedText.length, sitsContext.concordanceRetrieveData.searchedText.length);
      }
    }, 1000);
  }, []);

  const fnCallForGettingResults = () => {
    getResultsHandler(sitsContext.concordanceRetrieveData.searchedText);
    sitsContext.updateConcordanceRetrieveData('noMatchesIndicator', false);
  };

  useLayoutEffect(() => {
    if (sitsContext.concordanceRetrieveData.noMatchesIndicator === true) {
      fnCallForGettingResults();
      setSearchValue(sitsContext.concordanceRetrieveData.searchedText);
      setDefaultValue(sitsContext.concordanceRetrieveData.searchedText);
    }
  }, [sitsContext.concordanceRetrieveData.noMatchesIndicator]);

  useLayoutEffect(() => {
    const editorBottomLeftHeaderSectionElement = document.getElementById('editorBottomLeftHeaderSection');
    if (searchResults.length !== 0) {
      setIsLoading(true);
      if ((sitsContext.editorBottomSectionHeight !== 0)
      && (editorBottomLeftHeaderSectionElement.offsetHeight !== 0)) {
        const searchBarElement = document.getElementById('concordanceContainer');
        const stylesSearchBar = window.getComputedStyle(searchBarElement);
        const searchBarMarginTop = parseFloat(stylesSearchBar.marginTop);
        const searchBarTotalHeight = ((searchBarElement.offsetHeight) + searchBarMarginTop);
        const concordanceContainerElemenet = document.getElementById('concordanceTable');
        const stylesConcordanceResults = window.getComputedStyle(concordanceContainerElemenet);
        const concordanceResultsMarginTop = parseFloat(stylesConcordanceResults.marginTop);
        // eslint-disable-next-line max-len
        const calculatedHeight = sitsContext.editorBottomSectionHeight
        - (editorBottomLeftHeaderSectionElement.offsetHeight + searchBarTotalHeight) - 1;
        concordanceContainerElemenet.style.height = '5px';
        concordanceContainerElemenet.style.height = (calculatedHeight - concordanceResultsMarginTop) + 'px';
      }
      // eslint-disable-next-line array-callback-return
      searchResults.map((searchData) => {
        let sourceLangElement = document.getElementById('sourceLangId-' + searchData.tuID + searchData.indexId);
        let targetLangElement = document.getElementById('targetLangId-' + searchData.tuID + searchData.indexId);
        // eslint-disable-next-line max-len
        if ((sourceLangElement !== undefined) && (targetLangElement !== undefined)) {
          let sourceLangElementHeight = sourceLangElement.offsetHeight;
          let targetLangElementHeight = targetLangElement.offsetHeight;
          if (sourceLangElementHeight >= targetLangElementHeight) {
            targetLangElement.style.height = '5px';
            targetLangElement.style.height = (sourceLangElement.offsetHeight - 10) + 'px';
            targetLangElement.style.overflow = 'hidden';
          } else {
            sourceLangElement.style.height = '5px';
            sourceLangElement.style.height = (targetLangElement.offsetHeight - 10) + 'px';
            sourceLangElement.style.overflow = 'hidden';
          }
          if (handleLesserWidth === false) {
            let matchesElement = document.getElementById('matchPercentage-' + searchData.tuID + searchData.indexId);
            if (targetLangElement.offsetHeight > (matchesElement.offsetHeight + 8)) {
              let targetLangElementHalf = (targetLangElement.offsetHeight) / 2;
              let matchesElementHalf = matchesElement.offsetHeight / 2;
              let calcTranslateHeight = targetLangElementHalf - matchesElementHalf;
              matchesElement.style.transform = `translateY(${calcTranslateHeight}px)`;
            }
          }
          setIsSearchClicked(false);
          setIsLoading(false);
        }
      });
    }
    // }
  }, [searchResults, sitsContext.editorBottomSectionHeight]);

  useLayoutEffect(() => {
    const searchBarInputFieldElement = document.getElementById('searchbar_concordance');
    const customSearchIcon = document.getElementById('concordSearchBarCustomClearIcon');
    const editorBottomLeftHeaderSectionElement = document.getElementById('editorBottomLeftHeaderSection');
    if (customSearchIcon !== undefined) {
      const searchBarPositionDetails = searchBarInputFieldElement.getBoundingClientRect();
      customSearchIcon.style.left = `${searchBarPositionDetails.right - 40 - 48}px`;
    }
    if ((props.editorLeftWidth !== 0) && (props.editorLeftWidth < 450)) {
      setHandleLesserWidth(true);
    } else if ((props.editorLeftWidth !== 0) && (props.editorLeftWidth > 450)) {
      if (handleLesserWidth === true) {
        setHandleLesserWidth(false);
      }
      if (searchResults.length !== 0) {
        setIsLoading(true);
        if ((sitsContext.editorBottomSectionHeight !== 0)
        && (editorBottomLeftHeaderSectionElement.offsetHeight !== 0)) {
          const searchBarElement = document.getElementById('concordanceContainer');
          const stylesSearchBar = window.getComputedStyle(searchBarElement);
          const searchBarMarginTop = parseFloat(stylesSearchBar.marginTop);
          const searchBarTotalHeight = ((searchBarElement.offsetHeight) + searchBarMarginTop);
          const concordanceContainerElemenet = document.getElementById('concordanceTable');
          const stylesConcordanceResults = window.getComputedStyle(concordanceContainerElemenet);
          const concordanceResultsMarginTop = parseFloat(stylesConcordanceResults.marginTop);
          // eslint-disable-next-line max-len
          const calculatedHeight = sitsContext.editorBottomSectionHeight
          - (editorBottomLeftHeaderSectionElement.offsetHeight + searchBarTotalHeight) - 1;
          concordanceContainerElemenet.style.height = '5px';
          concordanceContainerElemenet.style.height = (calculatedHeight - concordanceResultsMarginTop) + 'px';
        }
        // eslint-disable-next-line array-callback-return
        searchResults.map((searchData) => {
          let sourceLang = document.getElementById('sourceLangId-' + searchData.tuID + searchData.indexId);
          let targetLang = document.getElementById('targetLangId-' + searchData.tuID + searchData.indexId);
          let sourceLangContentElement = sourceLang.firstChild;
          let targetLangContentElement = targetLang.firstChild;
          // eslint-disable-next-line max-len
          if ((sourceLang !== undefined) && (targetLang !== undefined)) {
            let sourceLangElementHeight = sourceLangContentElement.offsetHeight;
            let targetLangElementHeight = targetLangContentElement.offsetHeight;
            if (sourceLangElementHeight >= targetLangElementHeight) {
              sourceLang.style.height = '5px';
              sourceLang.style.height = (sourceLangElementHeight) + 'px';
              sourceLang.style.overflow = 'hidden';
              targetLang.style.height = '5px';
              targetLang.style.height = (sourceLangElementHeight) + 'px';
              targetLang.style.overflow = 'hidden';
            } else {
              sourceLang.style.height = '5px';
              sourceLang.style.height = (targetLangElementHeight) + 'px';
              sourceLang.style.overflow = 'hidden';
              targetLang.style.height = '5px';
              targetLang.style.height = (targetLangElementHeight) + 'px';
              targetLang.style.overflow = 'hidden';
            }
            if (handleLesserWidth === false) {
              let matchesElement = document.getElementById('matchPercentage-' + searchData.tuID + searchData.indexId);
              if (targetLang.offsetHeight > (matchesElement.offsetHeight + 8)) {
                let targetLangElementHalf = (targetLang.offsetHeight) / 2;
                let matchesElementHalf = matchesElement.offsetHeight / 2;
                let calcTranslateHeight = targetLangElementHalf - matchesElementHalf;
                matchesElement.style.transform = `translateY(${calcTranslateHeight}px)`;
              }
            }
            setIsSearchClicked(false);
            setIsLoading(false);
          }
        });
      }
    // }
    }
  }, [props.editorLeftWidth]);

  const searchOnChangeHandler = (e) => {
    if (trimSpace === true) {
      document.getElementById('searchbar_concordance').value = document.getElementById('searchbar_concordance').value.replace(/^\s*|\s*$/g, '');
      setTrimSpace(false);
    }
    displayCustomIcon = false;
    sitsContext.updateConcordanceRetrieveData('searchedText', e.target.value);
    setSearchValue(e.target.value);
  };

  const clearResultsHandler = () => {
    setDefaultValue(null);
    setIsResultsEmpty(false);
    setSearchResults([]);
    const clearedObj = {
      searchedText: null,
      searchedResults: [],
      scrollPosition: 0,
      noResults: false,
      noMatchesIndicator: false,
    };
    sitsContext.updateConcordanceRetrieveData('multiplePropertyChange', JSON.parse(JSON.stringify(clearedObj)));
    setIsDisplayingResults(false);
    setIsLoading(false);
    setClearedResults(true);
  };

  const clearFieldHandler = () => {
    const searchBarElement = document.getElementById('searchbar_concordance');
    displayCustomIcon = false;
    setDefaultValue(null);
    setIsResultsEmpty(false);
    setSearchResults([]);
    const clearedObj = {
      searchedText: null,
      searchedResults: [],
      scrollPosition: 0,
      noResults: false,
      noMatchesIndicator: false,
    };
    sitsContext.updateConcordanceRetrieveData('multiplePropertyChange', JSON.parse(JSON.stringify(clearedObj)));
    setIsDisplayingResults(false);
    setIsLoading(false);
    setClearedResults(true);
    // Since this is our own clear icon, we need to retain the focus in the input field
    // when this custom icon is clicked
    searchBarElement.focus();
  };

  useEffect(() => {
    if (props.confirmButtonClicked === true) {
      clearFieldHandler();
      // displayCustomIcon = false;
    }
  }, [props.confirmButtonClicked]);

  const resultsScrollHandler = (e) => {
    sitsContext.updateConcordanceRetrieveData('scrollPosition', e.target.scrollTop);
  };

  return (
    <div>
      <div className="concordance_searchbar_container" id="concordanceContainer">
        <Search
          actions={null}
          className="concordance_searchbar"
          ariaLabel={lablesAndnames.editorPage_concordanceSearchLabel}
          clearBtnText={lablesAndnames.editorPage_concordanceClearButtonText}
          id="searchbar_concordance"
          ref={inputRef}
          placeholder={lablesAndnames.editorPage_concordanceSearchPlaceholder}
          submitBtnText={lablesAndnames.editorPage_concordanceSumbit}
          onPaste={() => trimExtraSpacesOnPaste()}
          defaultValue={(defaultValue !== null && defaultValue !== undefined) ? defaultValue : ''}
          onSearch={(event) => searchSubmitHandler(event)}
          onChange={(event) => searchOnChangeHandler(event)}
          onClear={(event) => clearResultsHandler(event)}
        />
        <div style={{ display: displayCustomIcon ? 'flex' : 'none' }} onClick={clearFieldHandler} className="concord-searchBar-crossIcon" id="concordSearchBarCustomClearIcon">
          <SSRIcon paths={crossSmall} className="concord-custom-clear-icon" />
        </div>
      </div>
      <div style={{ display: isLoading ? '' : 'none' }}>
        <Loadingcomponent loadingText={loadingText} />
      </div>
      {isResultsEmpty ? (
        <div className="no_results_bottomtab" style={{ display: isLoading ? 'none' : '' }}>
          <div>
            {' '}
            <SSRIcon className="information_circle_color" paths={information} />
          </div>
          <div>
            {negativeFlowMsg.noResultsforConcordanceSearch}
          </div>
        </div>
      ) : ' ' }
      {((!isResultsEmpty) && (handleLesserWidth === false)) && (
      <div
        onScroll={resultsScrollHandler}
        id="concordanceTable"
        style={
          {
            display: isLoading ? 'none' : '',
            overflowX: displayingResults ? 'auto' : 'hidden',
            overflowY: displayingResults && searchResults > 3 ? 'scroll' : 'none',
          }
        }
        className={`results_grandparent ${(sitsContext.editorHorizontalDragData.resizeFlag === true
          || sitsContext.editorResizeObj.resizeFlag === true) ? 'concordance_container_userselection' : ''}`}
      >
        {searchResults.map((resultData) => (
          <div key={resultData.indexId} className="results_parent">
            <div className="concordance_first_child">
              <div id={'sourceLangId-' + resultData.tuID + resultData.indexId} className="textarea_readonly" dangerouslySetInnerHTML={{ __html: '<span classname="source_span_tag">' + resultData.coloredSourcetext + '</span>' }}></div>
              <FormField fieldHelper={{
                id: 'helper-msg-id-concordance',
                msg: `${(resultData.textType === null || resultData.textType === '') ? 'Null' : resultData.textType} | ${resultData.creationDate} | ${resultData.createdBy}`,
                type: '',
              }}
              />
            </div>
            <div className="concordance_second_child">
              <div id={'targetLangId-' + resultData.tuID + resultData.indexId} className={rtlLang === true ? 'textarea_readonly textarea_rtlDirect' : 'textarea_readonly'} dangerouslySetInnerHTML={{ __html: '<span classname="target_span_tag">' + resultData.translation + '</span>' }}></div>
            </div>
            <div className="concordance_third_child" id={'matchPercentage-' + resultData.tuID + resultData.indexId}>
              {resultData.baseScorce === 100 && <CommercialMessage className="concordance_percentage_high_accuracy" variant="nlp" message={resultData.baseScorce + '%'} /> }
              {(resultData.baseScorce < 100) && <CommercialMessage className="concordance_percentage_avg_accuracy" variant="nlp" message={resultData.baseScorce + '%'} /> }
            </div>
          </div>
        ))}
      </div>
      )}
      {((!isResultsEmpty) && (handleLesserWidth === true)) && (
      <div onScroll={resultsScrollHandler} id="concordanceTable" style={{ display: isLoading ? 'none' : '', overflowX: displayingResults ? 'auto' : 'hidden', overflowY: displayingResults ? 'scroll' : 'hidden' }} className="results_grandparent">
        {searchResults.map((resultData) => (
          <div key={resultData.indexId} className="results_parent_responsive">
            <div className="concordance_first_child_responsive">
              <div id={'sourceLangId-' + resultData.tuID + resultData.indexId} className="textarea_readonly" dangerouslySetInnerHTML={{ __html: '<span classname="source_span_tag">' + resultData.coloredSourcetext + '</span>' }}></div>
            </div>
            <div className="concordance_second_child_responsive">
              <div id={'targetLangId-' + resultData.tuID + resultData.indexId} className={rtlLang === true ? 'textarea_readonly textarea_rtlDirect' : 'textarea_readonly'} dangerouslySetInnerHTML={{ __html: '<span classname="target_span_tag">' + resultData.translation + '</span>' }}></div>
              <FormField fieldHelper={{
                id: 'helper-msg-id-concordance',
                msg: `${(resultData.textType === null || resultData.textType === '') ? 'Null' : resultData.textType} | ${resultData.creationDate} | ${resultData.createdBy}`,
                type: '',
              }}
              />
            </div>
            <div className="concordance_third_child_responsive" id={'matchPercentage-' + resultData.tuID + resultData.indexId}>
              {resultData.baseScorce === 100 && <CommercialMessage className="concordance_percentage_high_accuracy" variant="nlp" message={resultData.baseScorce + '%'} /> }
              {(resultData.baseScorce < 100) && <CommercialMessage className="concordance_percentage_avg_accuracy" variant="nlp" message={resultData.baseScorce + '%'} /> }
            </div>
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default Concordance;
