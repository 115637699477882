/* eslint-disable no-sparse-arrays */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useContext, useState } from 'react';
import './EditorBottomRightHeader.scss';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/carousel/style.scss';
import '@ingka/tabs/style.scss';
import '@ingka/focus/style.scss';
import SitsContext from '../../../../context/SitsContext';
import lablesAndnames from '../../../../lablesAndnames/labelsForComponents.json';

const EditorBottomRightHeader = (props) => { // Tab change functionality
  const sitsContext = useContext(SitsContext);
  const pageRedirectHandler = (pageName) => {
    sitsContext.updateEditorBottomRightHeader(pageName);
  };
  const [tabs, setTabs] = useState([
    <Tab
      key="tab_0"
      onClick={() => pageRedirectHandler('connectedProducts')}
      text={
        (props.selectedSegment.initialRendering === true
          || (props.selectedSegment.confirmButtonClicked === true)
          || (props.selectedSegment.textRowChanged === true)) ? lablesAndnames.tab_connectedProducts
          : `${lablesAndnames.tab_connectedProducts} (${sitsContext.connectedProdData.itemsConnected.length})`
      }
      tabPanelId="tab_0"
      disabled={props.selectedSegment.confirmButtonClicked}
    />,
    <Tab
      key="tab_1"
      onClick={() => pageRedirectHandler('history')}
      text={
          (props.selectedSegment.initialRendering === true
              || (props.selectedSegment.confirmButtonClicked === true)
              || (props.selectedSegment.textRowChanged === true)) ? lablesAndnames.tab_history
            : `${lablesAndnames.tab_history} (${sitsContext.historyTabCount})`
        }
      tabPanelId="tab_1"
      disabled={props.selectedSegment.confirmButtonClicked}
    />,
  ]);

  const [tabPanels, setTabPanels] = useState([
    <TabPanel
      key="tab-0"
      tabPanelId="tab_0"
    />,
    <TabPanel
      key="tab-1"
      tabPanelId="tab_1"
    />,
  ]);

  const changetab = (e) => {
    sessionStorage.setItem('selectedtab', e); // selectedtab is stored in sesssion storage , to retrive value when the page is reloaded
  };

  const updateTabsOnOperationsHandler = () => {
    const updatedTabs = [
      <Tab
        key="tab_0"
        onClick={() => pageRedirectHandler('connectedProducts')}
        text={
          (props.selectedSegment.initialRendering === true
            || (props.selectedSegment.confirmButtonClicked === true)
            || (props.selectedSegment.textRowChanged === true)) ? lablesAndnames.tab_connectedProducts
            : `${lablesAndnames.tab_connectedProducts} (${sitsContext.connectedProdData.itemsConnected.length})`
        }
        tabPanelId="tab_0"
        disabled={props.selectedSegment.confirmButtonClicked}
      />, <Tab
        key="tab_1"
        onClick={() => pageRedirectHandler('history')}
        text={
          (props.selectedSegment.initialRendering === true
              || (props.selectedSegment.confirmButtonClicked === true)
              || (props.selectedSegment.textRowChanged === true)) ? lablesAndnames.tab_history
            : `${lablesAndnames.tab_history} (${sitsContext.historyTabCount})`
        }
        tabPanelId="tab_1"
        disabled={props.selectedSegment.confirmButtonClicked}
      />,
    ];
    setTabs(updatedTabs);
  };

  useEffect(() => {
    updateTabsOnOperationsHandler();
  }, [
    sitsContext.historyTabCount,
    sitsContext.connectedProdData.itemsConnected,
    props.selectedSegment.confirmButtonClicked,
    props.selectedSegment.initialRendering,
    props.selectedSegment.textRowChanged,
  ]);

  return (
    <div id="editorBottomRightHeaderSection" className={sitsContext.editorBottomRotateHeader.editorRightHeaderRotate === true ? 'editor_bottom_header_margin editor_header_rotate' : 'editor_bottom_header_margin'}>
      <Tabs
        tabs={tabs}
        tabPanels={tabPanels}
        onTabChanged={changetab}
        defaultActiveTab="tab_0"
        className={props.selectedSegment.confirmButtonClicked === true ? 'tabs_bottom_right tabs_bottom_right_disabled_tab' : 'tabs_bottom_right'}
      />
    </div>
  );
};

export default EditorBottomRightHeader;
