/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-danger */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable */
import React, { useState, useLayoutEffect, useContext, useRef, useEffect } from 'react';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/focus/style.scss';
import FormField from '@ingka/form-field';
import CommercialMessage from '@ingka/commercial-message';
import semiAutomaticCountries from '../../../../countriesData/semiAutomaticCountries.json'
import rtlDirectionCountries from '../../../../countriesData/rtlCountries.json'
import { selectedPopupValue } from '../../../../App'
import ellipsesvertical from "@ingka/ssr-icon/paths/ellipses-vertical";
import Button from '@ingka/button';
import Loadingcomponent from '../../../../common-components/loader/Loadingcomponent';
import SitsContext from '../../../../context/SitsContext';
import './Matches.scss';
import SSRIcon from '@ingka/ssr-icon';
import '../../../../App.scss'
import information from "@ingka/ssr-icon/paths/information-circle";
import negativeFlowMsg from '../../../../messages/negativeFlowMessages.json'

const Matches = (props) => {
  const sitsContext = useContext(SitsContext);
  const ellipsisRef = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const [handleLesserWidth, setHandleLesserWidth] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [rtlLang, setRtlLang] = useState(false);

  useLayoutEffect(() => {
    const matchesContainerElement = document.getElementById('matchesContainer');
    const calcMaxWidth = (85 * (window.innerWidth)) / 100;
    matchesContainerElement.style.maxWidth = (calcMaxWidth) + 'px';
    const selectedTargetLang = selectedPopupValue.selectedlang;
    if (rtlDirectionCountries.includes(selectedTargetLang.toUpperCase())) {
      setRtlLang(true);
    } else if (rtlLang === true) {
      setRtlLang(false);
    }
    setTimeout(() => {
      if ((sitsContext.changeMatchesDetails === true) && (sitsContext.matchesRetrieveData !== null)) {
        const el = document.getElementById('matchesContainer');
        el.scrollTo(0, sitsContext.matchesRetrieveData);
      }
    }, 100);
  }, [])

  useLayoutEffect(() => {
    const editorBottomLeftHeaderSectionElement = document.getElementById('editorBottomLeftHeaderSection');
    // if (editorBottomLeftHeaderSectionElement !== undefined) {
    //   props.updateEditorHeaderHeight(editorBottomLeftHeaderSectionElement.offsetHeight);
    // }
    if (props.selectedSegment.confirmButtonClicked === false){
    if ((sitsContext.editorBottomSectionHeight !== 0)
    && (editorBottomLeftHeaderSectionElement.offsetHeight !== 0)){
      const matchesContainerElement = document.getElementById('matchesContainer');
      const calculatedHeight = sitsContext.editorBottomSectionHeight
      - editorBottomLeftHeaderSectionElement.offsetHeight - 1;
      const stylesMatchesContainer = window.getComputedStyle(matchesContainerElement);
      const matchesContainerMarginTop = parseFloat(stylesMatchesContainer.marginTop);
      matchesContainerElement.style.height = "5px";
      matchesContainerElement.style.height = (calculatedHeight - matchesContainerMarginTop) + "px";
    }
   if (sitsContext.changeMatchesDetails === true) {
    if ((props.selectedSegment.textId !== null) && (props.selectedSegment.partName !== null) && 
    (props.selectedSegment.partId !== null)) {
      setIsLoading(true);
      sitsContext.segmentsDetails.forEach((indSegment, index) => {
        if ((props.selectedSegment.textId === indSegment.textId) && (props.selectedSegment.partName === indSegment.partName) && 
         (props.selectedSegment.partId === indSegment.partId) && (props.selectedSegment.segmentId === indSegment.segmentId)) { 
          // indSegment.translationsSuggestions.forEach((suggestionsDetails, index) => {
            ellipsisRef.current = ellipsisRef.current.slice(0, indSegment.length);
            if ((indSegment.translation !== null) && (indSegment.translation !== "")) {
              let sourceLangElement = document.getElementById('sourceLangId-' + indSegment.textId + indSegment.partId + indSegment.partName + indSegment.segmentId + indSegment.indexId);
              let targetLangElement = document.getElementById('targetLangId-' + indSegment.textId + indSegment.partId + indSegment.partName + indSegment.segmentId + indSegment.indexId);
              if ((sourceLangElement !== undefined) && (targetLangElement !== undefined)) {
                let sourceLangElementHeight = sourceLangElement.offsetHeight;
                let targetLangElementHeight = targetLangElement.offsetHeight;
                if (sourceLangElementHeight >= targetLangElementHeight){
                  targetLangElement.style.height = "5px";
                  targetLangElement.style.height = (sourceLangElement.offsetHeight - 10) + "px";
                  targetLangElement.style.overflow = 'hidden';
                } else {
                  sourceLangElement.style.height = "5px";
                  sourceLangElement.style.height = (targetLangElement.offsetHeight - 10) + "px";
                  sourceLangElement.style.overflow = 'hidden';
                }
                if (handleLesserWidth === false) {
                  let matchesElement = document.getElementById('Matches-Percentage-' + indSegment.indexId);
                    if (targetLangElement.offsetHeight > (matchesElement.offsetHeight + 8)) {
                      let targetLangElementHalf = (targetLangElement.offsetHeight) / 2;
                      let matchesElementHalf = matchesElement.offsetHeight / 2;
                      let calcTranslateHeight = targetLangElementHalf - matchesElementHalf;
                      matchesElement.style.transform = `translateY(${calcTranslateHeight-2}px)`;
                      let contextMenuIconElement = document.getElementById('Context-Menu-' + indSegment.indexId);
                      contextMenuIconElement.style.transform = `translateY(${calcTranslateHeight-4}px)`;
                }
                }
              }
              }
            // })
        }
      })
      sitsContext.updateChangeMatchesDetails(false);
      setIsLoading(false);
    }
  }
}
  }, [props.selectedSegment, sitsContext.changeMatchesDetails, sitsContext.editorBottomSectionHeight, props.editorLeftWidth])

  useEffect(() => {
    if ((props.editorLeftWidth !== 0) && (props.editorLeftWidth < 450)) {
      setHandleLesserWidth(true);
    } else if ((props.editorLeftWidth !== 0) && (props.editorLeftWidth > 450)) {
      if (handleLesserWidth === true) {
        setHandleLesserWidth(false);
      }
      if ((props.selectedSegment.textId !== null) && (props.selectedSegment.partName !== null) && 
    (props.selectedSegment.partId !== null)) {
      setIsLoading(true);
      sitsContext.segmentsDetails.forEach((indSegment, index) => {
        if ((props.selectedSegment.textId === indSegment.textId) && (props.selectedSegment.partName === indSegment.partName) && 
         (props.selectedSegment.partId === indSegment.partId) && (props.selectedSegment.segmentId === indSegment.segmentId)) { 
          // indSegment.translationsSuggestions.forEach((suggestionsDetails, index) => {
            if ((indSegment.translation !== null) && (indSegment.translation !== "")) {
              let sourceLang = document.getElementById('sourceLangId-' + indSegment.textId + indSegment.partId + indSegment.partName + indSegment.segmentId + indSegment.indexId);
              let targetLang = document.getElementById('targetLangId-' + indSegment.textId + indSegment.partId + indSegment.partName + indSegment.segmentId + indSegment.indexId);
              let sourceLangContentElement = sourceLang.firstChild;
              let targetLangContentElement = targetLang.firstChild;
              if ((sourceLang !== undefined) && (targetLang !== undefined)) {
                let sourceLangElementHeight = sourceLangContentElement.offsetHeight;
                let targetLangElementHeight = targetLangContentElement.offsetHeight;
                if (sourceLangElementHeight >= targetLangElementHeight){
                  sourceLang.style.height = "5px";
                  sourceLang.style.height = (sourceLangElementHeight) + "px";
                  sourceLang.style.overflow = 'hidden';
                  targetLang.style.height = "5px";
                  targetLang.style.height = (sourceLangElementHeight) + "px";
                  targetLang.style.overflow = 'hidden';
                } else {
                  sourceLang.style.height = "5px";
                  sourceLang.style.height = (targetLangElementHeight) + "px";
                  sourceLang.style.overflow = 'hidden';
                  targetLang.style.height = "5px";
                  targetLang.style.height = (targetLangElementHeight) + "px";
                  targetLang.style.overflow = 'hidden';
                }
                if (handleLesserWidth === false) {
                  let matchesElement = document.getElementById('Matches-Percentage-'
                  + indSegment.indexId);
                  if (targetLang.offsetHeight > (matchesElement.offsetHeight + 8)) {
                    let targetLangElementHalf = (targetLang.offsetHeight) / 2;
                    let matchesElementHalf = matchesElement.offsetHeight / 2;
                    let calcTranslateHeight = targetLangElementHalf - matchesElementHalf;
                    matchesElement.style.transform = `translateY(${calcTranslateHeight-2}px)`;
                    let contextMenuIconElement = document.getElementById('Context-Menu-' + indSegment.indexId);
                    contextMenuIconElement.style.transform = `translateY(${calcTranslateHeight-4}px)`;
                  }
                }
              }
              }
            // })
        }
      })
      sitsContext.updateChangeMatchesDetails(false);
      setIsLoading(false);
    }
    }
  }, [props.editorLeftWidth]);

  const handleOutSideClick = (event) => {
    if (selectedID !== null) {
     if (sitsContext.contextMenuIconClicked === true) { 
      if (!document.getElementById(selectedID).contains(event.target)) {
        setTimeout(() => {
          const positionObj = {
            topPositionValue: null,
            leftPositionValue: null,
            rightPositionValue: null,
            bottomPositionValue: null,
            heightEllipsisIcon: null,
          }
          sitsContext.updateContextMenuIconClicked(false);
          sitsContext.updateContextMenuPosition(positionObj);
          const selectedMatchesSuggestion = {
            textId: null,
            partId: null,
            partName: null,
            segmentId: null,
            indexId: null,
            translationText: null,
            translationAccuracy: null,
            sourceText: null,
          }
          sitsContext.updateMatchesSuggestion(selectedMatchesSuggestion);
        }, 250);
      }
    }
    }
    // }
  };

  useEffect(() => {
    if (selectedID !== null) {
      window.addEventListener("mousedown", handleOutSideClick);
    }

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [selectedID]);


  const contextMenuHandler = (event, individualSegmentData, individualSegment, id, contextText, matchesParentId) => {
    setSelectedID(id);
    if (sitsContext.contextMenuIconClicked === false) {
      let calculatedTopPositionValue = 0;
      const ele = document.getElementById(id);
      const ele2 = document.getElementById('matchesContainer');
      const matchesContainerElement = document.getElementById('matchesContainer');
      const matchesParentElement = document.getElementById(matchesParentId);
      const editorHeaderElement = document.getElementById('editorHeader');
      const bannerElement = document.getElementById('bannerDiv');
      const rect = ele.getBoundingClientRect();
      const rect2 = ele2.getBoundingClientRect();
      const matchesParentValues = matchesParentElement.getBoundingClientRect();
      if (sitsContext.bannerVisible === true) {
        calculatedTopPositionValue = rect.top - (editorHeaderElement.offsetHeight
          + bannerElement.offsetHeight);
      } else {
        calculatedTopPositionValue = rect.top - editorHeaderElement.offsetHeight;
      }
      let positionObj = {
        topPositionValue: calculatedTopPositionValue,
        leftPositionValue: rect.left,
        rightPositionValue: rect.right,
        bottomPositionValue: rect.bottom,
        heightEllipsisIcon: rect.height,
      }
      sitsContext.updateContextMenuPosition(positionObj);
      sitsContext.updateContextMenuIconClicked(true);
      const selectedMatchesSuggestion = {
        textId: individualSegmentData.textId,
        partId: individualSegmentData.partId,
        partName: individualSegmentData.partName,
        segmentId: individualSegmentData.segmentId,
        indexId: individualSegment.indexId,
        translationText: individualSegment.translation,
        translationAccuracy: individualSegment.baseScorce,
        sourceText: individualSegment.sourcetext,
      }
      sitsContext.updateMatchesSuggestion(selectedMatchesSuggestion);
    } else if (sitsContext.contextMenuIconClicked  === true) {
      const positionObj = {
        topPositionValue: null,
        leftPositionValue: null,
        rightPositionValue: null,
        bottomPositionValue: null,
        heightEllipsisIcon: null,
      }
      sitsContext.updateContextMenuIconClicked(false);
      sitsContext.updateContextMenuPosition(positionObj);
      const selectedMatchesSuggestion = {
        textId: null,
        partId: null,
        partName: null,
        segmentId: null,
        indexId: null,
        translationText: null,
        translationAccuracy: null,
        sourceText: null,
      }
      sitsContext.updateMatchesSuggestion(selectedMatchesSuggestion);
    }
  };
  
  const changetoconcordance = () => {
    sitsContext.updateConcordanceRetrieveData('noMatchesIndicator', true);
    sitsContext.updateConcordanceRetrieveData('tabChange', true);
    sitsContext.updateConcordanceRetrieveData('searchedText', props.selectedSegment.sourceSegmentText);
    props.tabChangeHandler(true); // Header change
    sitsContext.updateEditorBottomLeftHeader('concordance'); // Body change
  }

  const matchesResultsScrollHandler = (e) => {
    sitsContext.updateMatchesRetrieveData(e.target.scrollTop);
  };

  return (
   <div
     onScroll={matchesResultsScrollHandler}
     id="matchesContainer"
     className={
      handleLesserWidth === true
      ? `matches_container matches_container_responsive ${(sitsContext.editorHorizontalDragData.resizeFlag === true
      || sitsContext.editorResizeObj.resizeFlag === true) ? 'matches_container_userselection' : '' }`
      : `matches_container ${(sitsContext.editorHorizontalDragData.resizeFlag === true
        || sitsContext.editorResizeObj.resizeFlag === true) ? 'matches_container_userselection' : '' }`
      }
   >
    <div style={{ display: isLoading ? '' : 'none' }}>
        <Loadingcomponent loadingText={loadingText} />
    </div>
    {(props.selectedSegment.confirmButtonClicked === true) ? <div className="no_results_bottomtab" >
      <div> <SSRIcon className="information_circle_color" paths={information}/></div>
     <div>{negativeFlowMsg.noSegemntSelectedinEditorPage}</div>
    </div> : ' ' }
    {(sitsContext.matchesSuggestionsCount === 0 && props.selectedSegment.confirmButtonClicked === false) ? <div className="no_results_bottomtab" >
      <div> <SSRIcon className="information_circle_color" paths={information}/></div>
     <div className="no_results_matches_text">{negativeFlowMsg.noMatchesFoundInEditorPage}<br/>
      Try using <u  className= "Pialabel" onClick={changetoconcordance}>concordance</u> search.</div>
    </div> : ' ' }
    {handleLesserWidth === false && sitsContext.matchesSuggestionsCount !== 0 && props.selectedSegment.confirmButtonClicked === false && <div className="matches_grandparent" style={{ display: isLoading ? 'none' : '' }}>
      {sitsContext.segmentsDetails.map((individualSegment, index) => (
        ((individualSegment.textId === props.selectedSegment.textId) && (individualSegment.partName === props.selectedSegment.partName)
        && (individualSegment.partId === props.selectedSegment.partId) && (individualSegment.segmentId === props.selectedSegment.segmentId) &&
         ((individualSegment.translation !== null) && (individualSegment.translation !== "") && (<div id={'matchesparent-' + individualSegment.textId + individualSegment.indexId} className={handleLesserWidth === true ? 'matches_parent matches_parent_responsive': 'matches_parent'} key={individualSegment.indexId}>
          <div className={handleLesserWidth === true ? 'matches_first_child matches_first_child_responsive' : 'matches_first_child'}>
            <div id={'sourceLangId-' + individualSegment.textId + individualSegment.partId + individualSegment.partName + individualSegment.segmentId + individualSegment.indexId} dangerouslySetInnerHTML={{ __html: '<span classname="source_span_tag">' + individualSegment.coloredSourceText + '</span>'  }} className='textarea_readonly'></div>
            <FormField fieldHelper={{
             id: 'helper-msg-id',
             msg:`${(individualSegment.textType === null || individualSegment.textType === '') ? 'Null' : individualSegment.textType} | ${individualSegment.creationDate} | ${individualSegment.createdBy}`,
             type: ''
            }}/>
           </div>
           <div className={handleLesserWidth === true ? 'matches_second_child matches_second_child_responsive' : 'matches_second_child' }>
             <div id={'targetLangId-' + individualSegment.textId + individualSegment.partId + individualSegment.partName + individualSegment.segmentId + individualSegment.indexId} dangerouslySetInnerHTML={{ __html: '<span classname="target_span_tag">' + individualSegment.translation + '</span>'}} className={rtlLang === true ? 'textarea_readonly textarea_rtlDirect' : 'textarea_readonly'}></div>
          {semiAutomaticCountries.includes(selectedPopupValue.selectedlangvalue.toUpperCase())
          && <FormField
          className={individualSegment.translatedCountryCode.toUpperCase() === selectedPopupValue.selectedlangvalue.split('-')[1]
          ? 'suggestions_sub_formfield' : 'suggestions_sub_formfield_nomatch' }
          fieldHelper={{
             id: 'helper-msg-id',
             msg: `${(individualSegment.localeName === null || individualSegment.localeName === '') ? 'Null' : individualSegment.localeName}`,
             type: ''
            }}/>
            }
           </div>
           <div className='matches_third_child' id={'Matches-Percentage-' + individualSegment.indexId}>
            {individualSegment.baseScorce === 100 && <CommercialMessage id={'Matches-Percentage-Child-' + individualSegment.indexId} className='matches_percentage_high_accuracy' variant="nlp" message={individualSegment.baseScorce + '%'} /> }
            {(individualSegment.baseScorce < 100) && <CommercialMessage id={'Matches-Percentage-Child-' + individualSegment.indexId} className='matches_percentage_avg_accuracy' variant="nlp" message={individualSegment.baseScorce + '%'} /> }
           </div>
           <div className='matches_fourth_child' id={'Context-Menu-' + individualSegment.indexId}>
             {/* <SSRIcon paths={ellipsesvertical}/> */}
             {((individualSegment.textId === sitsContext.selectedMatchesSuggestion.textId) &&
             (individualSegment.partId === sitsContext.selectedMatchesSuggestion.partId) &&
             (individualSegment.partName === sitsContext.selectedMatchesSuggestion.partName) &&
             (individualSegment.indexId === sitsContext.selectedMatchesSuggestion.indexId)) ?
             <Button
               ref={el => ellipsisRef.current[individualSegment.indexId] = el}
               id={'ellipsisButton-' + individualSegment.indexId}
               className='ellipsis_button_matches ellipsis_button_pressed'
               onClick={event => contextMenuHandler(event,individualSegment, individualSegment, 'ellipsisButton-' + individualSegment.indexId, 'Onclick Handler', 'matchesparent-' + individualSegment.textId + individualSegment.indexId)}
               href=""
               iconOnly
               ssrIcon={ellipsesvertical}
               text="ellipsis"
               type="tertiary"
             /> : <Button
             ref={el => ellipsisRef.current[individualSegment.indexId] = el}
             id={'ellipsisButton-' + individualSegment.indexId}
             className='ellipsis_button_matches'
             onClick={event => contextMenuHandler(event,individualSegment, individualSegment, 'ellipsisButton-' + individualSegment.indexId, 'Onclick Handler', 'matchesparent-' + individualSegment.textId + individualSegment.indexId)}
             href=""
             iconOnly
             ssrIcon={ellipsesvertical}
             text="ellipsis"
             type="tertiary"
            />
           }
           </div>
          </div> )
         )
        )
        ))}
    </div>}

    {handleLesserWidth === true && props.selectedSegment.confirmButtonClicked === false && <div className="matches_grandparent" style={{ display: isLoading ? 'none' : '' }}>
      {sitsContext.segmentsDetails.map((individualSegment, index) => (
        ((individualSegment.textId === props.selectedSegment.textId) && (individualSegment.partName === props.selectedSegment.partName)
        && (individualSegment.partId === props.selectedSegment.partId) && (individualSegment.segmentId === props.selectedSegment.segmentId) &&
         ((individualSegment.translation !== null) && (individualSegment.translation !== "") && (<div id={'matchesparent-' + individualSegment.textId + individualSegment.indexId} className='matches_parent_responsive' key={individualSegment.indexId}>
          <div className='matches_first_child_responsive'>
            <div id={'sourceLangId-' + individualSegment.textId + individualSegment.partId + individualSegment.partName + individualSegment.segmentId + individualSegment.indexId} dangerouslySetInnerHTML={{ __html: '<span classname="source_span_tag">' + individualSegment.coloredSourceText + '</span>' }} className='textarea_readonly'></div>
           </div>
           <div className='matches_second_child_responsive'>
             <div id={'targetLangId-' + individualSegment.textId + individualSegment.partId + individualSegment.partName + individualSegment.segmentId + individualSegment.indexId} dangerouslySetInnerHTML={{ __html: '<span classname="target_span_tag">' +  individualSegment.translation + '</span>' }} className={rtlLang === true ? 'textarea_readonly textarea_rtlDirect' : 'textarea_readonly'}></div>
             <FormField fieldHelper={{
             id: 'helper-msg-id',
             msg: `${(individualSegment.textType === null || individualSegment.textType === '') ? 'Null' : individualSegment.textType} | ${individualSegment.creationDate} | ${individualSegment.createdBy}`,
             type: ''
            }}/>
           </div>
           <div className='matches_third_child_responsive' id={'Matches-Percentage-' + individualSegment.indexId}>
             <div className='matches_third_child_responsive_basescore'>
              {individualSegment.baseScorce === 100 && <CommercialMessage id={'Matches-Percentage-Child-' + individualSegment.indexId} className='matches_percentage_high_accuracy' variant="nlp" message={individualSegment.baseScorce + '%'} /> }
              {(individualSegment.baseScorce < 100) && <CommercialMessage id={'Matches-Percentage-Child-' + individualSegment.indexId} className='matches_percentage_avg_accuracy' variant="nlp" message={individualSegment.baseScorce + '%'} /> }
             </div>
             <div className='matches_third_child_responsive_icon' id={'Context-Menu-' + individualSegment.indexId}>
             {((individualSegment.textId === sitsContext.selectedMatchesSuggestion.textId) &&
             (individualSegment.partId === sitsContext.selectedMatchesSuggestion.partId) &&
             (individualSegment.partName === sitsContext.selectedMatchesSuggestion.partName) &&
             (individualSegment.indexId === sitsContext.selectedMatchesSuggestion.indexId)) ?
                <Button
                  ref={el => ellipsisRef.current[individualSegment.indexId] = el}
                  id={'ellipsisButton-' + individualSegment.indexId}
                  className='ellipsis_button_matches ellipsis_button_pressed'
                  onClick={event => contextMenuHandler(event,individualSegment, individualSegment, 'ellipsisButton-' + individualSegment.indexId, 'Onclick Handler', 'matchesparent-' + individualSegment.textId + individualSegment.indexId)}
                  href=""
                  iconOnly
                  ssrIcon={ellipsesvertical}
                  text="ellipsis"
                  type="tertiary"
               /> : <Button
                      ref={el => ellipsisRef.current[individualSegment.indexId] = el}
                      id={'ellipsisButton-' + individualSegment.indexId}
                      className='ellipsis_button_matches'
                      onClick={event => contextMenuHandler(event,individualSegment, individualSegment, 'ellipsisButton-' + individualSegment.indexId, 'Onclick Handler', 'matchesparent-' + individualSegment.textId + individualSegment.indexId)}
                      href=""
                      iconOnly
                      ssrIcon={ellipsesvertical}
                      text="ellipsis"
                      type="tertiary"
                    />
           }
           </div>
          </div> 
          </div> )
         )
         )
        ))}
    </div>}
  </div>  
  );
};

export default Matches;
