/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import React, {
  useState, useEffect, useRef, useContext, useLayoutEffect,
} from 'react';
import Pill from '@ingka/pill';
import '@ingka/pill/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import uparrow from '@ingka/ssr-icon/paths/chevron-up';
import '@ingka/button/style.scss';
import './Filtercomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/action-list/style.scss';
import '@ingka/focus/style.scss';
import ActionList, { ActionListItem } from '@ingka/action-list';
import retriveSortfilteritemNumber from '../data/sortfilterItemnumber.json';
import { itemNumberCheck } from '../searchBar/SearchBarComponent';
// import { selectedPage } from '../../../common-components/header/Header';
import SitsContext from '../../../context/SitsContext';
import { sortFilterValue, gSortValues } from '../data/Sortfilter';
import { selectedPopupValue } from '../../../App';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';

export let defaultSortValueforApi = [];

function Sortcomponent(props) {
  const sitsContext = useContext(SitsContext);
  const [Checked, setChecked] = useState([]);
  const [filtername, setFiltername] = useState([]);
  const [enable, setEnable] = useState(true);
  // intializing enable to render the component when there is a change in selected filters array
  const [newarray, setNewarray] = useState([]);
  // intializing newarray to update the filters values after the clear action is performed
  const [search, setSearch] = useState(false);
  const [select, setSelect] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    sortFilterValue();
  }, []);

  useEffect(() => {
    setChecked([]);
    setFiltername([]);
    setNewarray([]);
  }, [selectedPopupValue.selectedlangvalue]);

  useEffect(() => {
    if (search === true) {
      update(Checked, filtername);
    }
  }, [search]);
  // upadate function is called when there is any change in the search button in the dropdown
  useEffect(() => {
    if (sitsContext.currentStatePageName !== 'dashboard') {
      newArray(Checked);
    }
  }, [itemNumberCheck]);

  useLayoutEffect(() => {
    if (sitsContext.pageRedirection && (sitsContext.currentStatePageName !== 'dashboard')) {
      setTimeout(() => {
        setNewarray(sitsContext.previousStateSortFilter);
        newArray(sitsContext.previousStateSortFilter);
        const filterselectedvalues = {
          selectedfilters: sitsContext.previousStateSortFilterShortName,
          selectedfiltersName: sitsContext.previousStateSortFilter,
          searchselected: search,
        };
        props.SortFilter(filterselectedvalues);
        // setChecked(sitsContext.previousStateSortFilterShortName);
        // setFiltername(sitsContext.previousStateSortFilter);
      }, 100);
    } else if (sitsContext.startPagepreviousStateSortFilter.length !== 0
      && sitsContext.currentStatePageName === 'dashboard') {
      setTimeout(() => {
        setNewarray(sitsContext.startPagepreviousStateSortFilter);
        newArray(sitsContext.startPagepreviousStateSortFilter);
        const filterselectedvalues = {
          selectedfilters: sitsContext.startPagepreviousStateSortFilter,
          selectedfiltersName: sitsContext.startPagepreviousStateSortFilter,
          searchselected: search,
        };
        sitsContext.startPageupdatePreviousSortFilter(
          sitsContext.startPagepreviousStateSortFilter,
        );

        props.SortFilter(filterselectedvalues);
        // setChecked(sitsContext.previousStateSortFilterShortName);
        // setFiltername(sitsContext.previousStateSortFilter);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (props.filterarray !== null || props.filtervaluearray !== null) {
      update(Checked, filtername, props.filterarray, props.filtervaluearray);
    }
  }, [props.filterarray, props.filtervaluearray]);
  // update function is called when there is any change in the selected filters

  const onClickButton = () => {
    setModalOpen(!isModalOpen);
    setSelect(!select);
  };

  useOnClickOutside(ref, () => {
    setModalOpen(false);
    setSelect(false);
  });

  const handleChange = (e) => {
    sitsContext.updateInitialFilterFlag(true);
    const newChecked = [e.target.value];
    const newfiltername = [e.target.name];
    setChecked(newChecked);
    setFiltername(newfiltername);
    update(newChecked, newfiltername);
  };

  const update = (
    newChecked,
    newfiltername,
    cancelledarray,
    cancelledarrayvalue,
  ) => {
    const filtervalues = {
      selectedfilters:
        cancelledarrayvalue === null || cancelledarrayvalue === undefined
          ? newChecked
          : newChecked.filter((item) => cancelledarrayvalue.includes(item)),
      selectedfiltersName:
        cancelledarray === null || cancelledarray === undefined
          ? newfiltername
          : newfiltername.filter((item) => cancelledarray.includes(item)),
      searchselected: search,
    }; // array for combining all the filters data
    setEnable(true);
    setFiltername(filtervalues.selectedfiltersName);
    // filtersname array will be upadted with the new values
    setChecked(filtervalues.selectedfilters);
    // filtersvalues array will be upadted with the new values
    newArray(filtervalues.selectedfiltersName);
    // if (sitsContext.currentStatePageName !== 'dashboard') {
    //   sitsContext.updatePreviousSortFilter(
    //     filtervalues.selectedfiltersName,
    //     filtervalues.selectedfilters,
    //   );
    // }
    sitsContext.updateintialloading(true);
    props.SortFilter(filtervalues);
    // filtersdata is passed to other components through the props method
    setSearch(false);
  };

  const newArray = (selectedvalues) => {
    let defaultValue;
    if (selectedvalues !== undefined) {
      if (sitsContext.currentStatePageName === 'dashboard') {
        defaultValue = (selectedvalues.length === 0
          ? gSortValues[0].sort_filter_name : (selectedvalues.length === 0
            ? selectedvalues[0] : selectedvalues));
      } else if (sitsContext.currentStatePageName !== 'dashboard') {
        defaultValue = (itemNumberCheck === true
          ? gSortValues[1].sort_filter_name : gSortValues[0].sort_filter_name);
      }
      const itemCheckValue = (selectedvalues === undefined || selectedvalues.length === 0)
        ? defaultValue : selectedvalues;
      setNewarray((selectedvalues === undefined || selectedvalues.length === 0)
        ? defaultValue : selectedvalues);
      if (sitsContext.currentStatePageName !== 'dashboard') {
        sitsContext.updatePreviousSortFilter(
          itemCheckValue,
        );
      } else if (sitsContext.currentStatePageName === 'dashboard') {
        sitsContext.startPageupdatePreviousSortFilter(
          itemCheckValue,
        );
      }
      defaultSortValueforApi = (defaultValue === gSortValues[0].sort_filter_name ? '' : gSortValues[1].sort_filter_name);
      // array is updated with new values
    }
  };

  return (
    <div ref={ref}>
      <div>
        <div>
          <Pill
            className="Noto_font_family"
            // disabled={selectedPage === 'Dashboard' ? true : false}
            selected={!!select}
            small
            label={lablesAndnames.filter_labelForSortfilter}
            iconPosition="trailing"
            ssrIcon={select ? uparrow : downarrow}
            onClick={onClickButton}
          />
        </div>
      </div>

      {enable && (
        <div style={{ display: isModalOpen ? '' : 'none' }} className="parent_div_filter_position">
          <div className="dropdowndiv">
            <div>
              <ActionList className="action-lists Sort_dropdown" id="Sort_Filter" variant="compact">
                {(itemNumberCheck === true
                  ? retriveSortfilteritemNumber : gSortValues).map((sort, index) => (
                    <ActionListItem
                      key={index}
                      control="radiobutton"
                      controlProps={{
                        value: sort.sort_filter_value,
                        name: sort.sort_filter_name,
                        checked:
                      newarray.includes(sort.sort_filter_name) === true,
                      }}
                      label={sort.sort_filter_name}
                      onChange={handleChange}
                    />
                ))}
              </ActionList>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Sortcomponent;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
