/* eslint-disable import/no-cycle */
import React, { useEffect, useContext, useState } from 'react';
import './BulkEdit.scss';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';
import { selectedPage } from '../header/Header';
import SitsContext from '../../context/SitsContext';
import '../../App.scss';
import { sourceLocaleName } from '../../constants';
import lablesAndnames from '../../lablesAndnames/labelsForComponents.json';

export default function BulkEditingselect() {
  const navigate = useNavigate();
  const sitsContext = useContext(SitsContext);
  const [cssClassname, setCssClassname] = useState('noTextSelected');

  useEffect(() => {
    if (sitsContext.selectedtextsforbulkedit.length !== 0) {
      setCssClassname('isTextSelected editbulkselect');
    } else if (sitsContext.selectedtextsforbulkedit.length === 0) {
      setCssClassname('noTextSelected');
    }
  }, [sitsContext.selectedtextsforbulkedit]);

  const deselectAll = () => {
    sitsContext.updateSelectedtextsforbulkedit([]);
  };

  const sendBulkDatatoEditor = () => {
    let arrayOftextIds = [];
    const selectedtextIds = sitsContext.selectedtextsforbulkedit.map((data) => {
      const routingIndividual = {
        textid: (data.textid).indexOf('-') === -1 ? data.textid : (data.textid).split('-')[0],
        source_locale_code: data.source_locale_code,
        target_locale_code: data.target_locale_code,
        object_type_short: data.object_type_short,
        isParentEnabled: true,
      };
      return routingIndividual;
    });

    const listOfIdsSelected = sitsContext.selectedtextsforbulkedit.map((data) => {
      const routingIndividual = {
        textId: (data.textid).indexOf('-') === -1 ? data.textid : (data.textid).split('-')[0],
        objectNameShort: data.object_type_short,
      };
      if ((data.textid).split('-')[1] === 'translated') {
        arrayOftextIds = arrayOftextIds.concat((data.textid).indexOf('-') === -1 ? data.textid : (data.textid).split('-')[0]);
      }
      return routingIndividual;
    });

    sitsContext.updateBulkEditbuttonStatus(true);
    sessionStorage.setItem('funcCalled', 'true');
    navigate('/Editorpage', {
      state: {
        selectedPageTitle: selectedPage,
        selectedTextData: selectedtextIds,
        sourceLocale: sourceLocaleName,
        targetLocale: sitsContext.selectedLocaleName,
      },
    });
    if (sitsContext.autoSaveHeaderChange !== null) {
      sitsContext.updateAutoSaveHeaderChange(null);
    }
    sitsContext.updateDataOfRelaseTexts(listOfIdsSelected, arrayOftextIds);
    // eslint-disable-next-line max-len
    sitsContext.updateEditorSearchPageLocationData(selectedPage, selectedtextIds, sourceLocaleName, sitsContext.selectedLocaleName);
    sitsContext.updateFilterDisplayFlag(false);
    sitsContext.updateInitialFilterFlag(false);
    sitsContext.updateNavigateEditToSearchPageFlag(`${selectedPage}`);
  };

  return (
    <div id="bulkEditpromt" className={cssClassname}>
      <div className="display_content">
        <div className="display_content">
          <div className="textColor_White selectedtexts">
            {`${sitsContext.selectedtextsforbulkedit.length
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} text${
              sitsContext.selectedtextsforbulkedit.length > 1 ? 's' : ''
            } selected`}
          </div>
          <div className="deselectAll">
            <Button
              type="tertiary"
              inverseTheme
              text={lablesAndnames.button_deselectButtonText}
              onClick={() => deselectAll()}
            />
          </div>
        </div>
        <div className="bulkEditbutton">
          <Button
            type="tertiary"
            inverseTheme
            text={lablesAndnames.button_openEditorButtonText}
            onClick={() => sendBulkDatatoEditor()}
          />
        </div>
      </div>
    </div>
  );
}
