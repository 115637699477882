/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import rightarrow from '@ingka/ssr-icon/paths/chevron-right-small';
import gear from '@ingka/ssr-icon/paths/gear';
import checkmarkcircle from '@ingka/ssr-icon/paths/checkmark-circle';
import warningtriangle from '@ingka/ssr-icon/paths/warning-triangle';
import SSRIcon from '@ingka/ssr-icon';
import '../../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import { ENV_NAME } from '../../Config';
import TabComponent from './TabComponent';
import '@ingka/loading/style.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/focus/style.scss';
import lablesAndnames from '../../lablesAndnames/labelsForComponents.json';

/* eslint-disable */
import { tab_sel } from '../../pages/Editorpage';
import SitsContext from '../../context/SitsContext';

export let selectedPage = '';

const connectedProdInfo = {
  textId: null,
  itemsConnected: [],
}

const clearedObj = {
  searchedText: null,
  searchedResults: [],
  scrollPosition: 0,
  noResults: false,
  noMatchesIndicator: false,
};

const numberOfItemsAffectedLocalData = {
  openNumberOfItemsAffectedPopup: false,
  numberOfItemsAffectedFlag: false,
  isSegmentsInfoThere: null,
  isRevertChangesLoading:false,
}

function Header(props) {
  const sitsContext = useContext(SitsContext);
  const navigate = useNavigate();
  const selectedPathDetails = useLocation(); // to capture the current page
  selectedPage = (selectedPathDetails.pathname === '/' ? 'Dashboard' : ((selectedPathDetails.pathname).substring(1)).slice(0, -4)); 
  // to capture the path of the current page
  const [isHeaderChange, setIsHeaderChange] = useState(false);
  // To change the header when the editor page is selected

  useEffect(() => {
      navigate("/");
  }, [])

  useEffect(() => {
    if (selectedPage !== 'Editor') {
      setIsHeaderChange(true);
    } else {
      setIsHeaderChange(false);
    }
  }, [selectedPage]); // to change the header when Editor page is selected

  // onclick  of settings icon functionality
  const onclickHandler = () => {
    const value = true;
    props.onClickSettingHandler(value);
  };

  // onclick  of close button functionality for editor page
  const redirectToHome = () => {
    //Redirect to home page
    window.location.href="/"
  };

  const closeButtonHandler = () => {
    sitsContext.updateintialfiltervalue(false);
    // we update the tab change for the editor left bottom section (concordance) to be false, 
    // so that when user comes out of the editor page, again if they go back to the editor page,
    // we don't display the retrieved data in the concordance tab
    props.onAutoSaveHandler()
    sitsContext.updateEditorPageRender(false);
    sitsContext.updateConcordanceRetrieveData('tabChange', false);
    sitsContext.updateEnableSearchbuttoninfilter(false);
    // sitsContext.updateEditorBottomLeftHeader('matches');
    sitsContext.updateEditorBottomRightHeader('connectedProducts');
    // sitsContext.updateNavigateEditToSearchPageFlag(true);
    sitsContext.updateAutoSaveHeaderChange(null);
    sitsContext.updateIsConnectImageClicked('isImageClicked', false);
    sitsContext.updateMatchesRetrieveData(null);
    sitsContext.updateEditorBottomRotateHeader('editorRightHeaderRotate', false);
    sitsContext.updateEditorBottomRotateHeader('editorLeftHeaderRotate', false);
    sitsContext.updateConnectedProdResData(connectedProdInfo);
    sitsContext.updateConcordanceRetrieveData('multiplePropertyChange', JSON.parse(JSON.stringify(clearedObj)));
    sitsContext.updateHistoryTabData(false, [], 0);
    sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
    props.updateAutoSaveErrorFlag(false);
    sitsContext.resetValidationError();
  };

  const onReleaseHandler = () => {
    // we update the tab change for the editor left bottom section (concordance) to be false, 
    // so that when user comes out of the editor page, again if they go back to the editor page,
    // we don't display the retrieved data in the concordance tab
    sitsContext.updateEditorPageRender(false);
    sitsContext.updateConcordanceRetrieveData('tabChange', false);
    sitsContext.updateEnableSearchbuttoninfilter(false);
    // sitsContext.updateEditorBottomLeftHeader('matches');
    sitsContext.updateEditorBottomRightHeader('connectedProducts');
    if(tab_sel === 'Search'){
    sitsContext.updatePageRedirection(true);
    }
    sessionStorage.setItem("function", "release");
    props.onSaveTranslationHandler();
    sitsContext.updateAutoSaveHeaderChange(null);
    sitsContext.updateIsConnectImageClicked('isImageClicked', false);
    sitsContext.updateMatchesRetrieveData(null);
    sitsContext.updateEditorBottomRotateHeader('editorRightHeaderRotate', false);
    sitsContext.updateEditorBottomRotateHeader('editorLeftHeaderRotate', false);
    sitsContext.updateConnectedProdResData(connectedProdInfo);
    sitsContext.updateConcordanceRetrieveData('multiplePropertyChange', JSON.parse(JSON.stringify(clearedObj)));
    sitsContext.updateHistoryTabData(false, [], 0);
    sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
    props.updateAutoSaveErrorFlag(false);
    sitsContext.resetValidationError();
  };

  const headerClickHandler = () => {
    sitsContext.updateTabNavigation('tab_0');
    sitsContext.updateEnableSearchbuttoninfilter(false);
    sitsContext.updateintialfiltervalue(false);
    if (sitsContext.checkInitState) {
      sitsContext.updateFilterDisplayFlag(false);
      sitsContext.updateInitialFilterFlag(false);
      sitsContext.updatePageRedirection(true);
    }
    sitsContext.updateCurrentStatePageName('dashboard');
    sitsContext.updateConcordanceRetrieveData('multiplePropertyChange', JSON.parse(JSON.stringify(clearedObj)));
    sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
    props.updateAutoSaveErrorFlag(false);
    sitsContext.resetValidationError();
  };

  return (
  <div>
    {isHeaderChange && (
    <div>
      <div className="TopPanel header_panel">
        <div className="header_label">
         <Link className="link_nav"  to="/Dashboardpage" key="tab_0">
           <div className="header_application_name" onClick={headerClickHandler}>
             SITS
           </div>
         </Link>  
        </div>
        {ENV_NAME !== 'PRODUCTION' && <div className="message_label">
          <label className="Pialabel cursor_default">{`Hej, Please be aware that this is a ${ENV_NAME === 'DEVELOPMENT' ? 'test' : (ENV_NAME === 'STAGING' ? 'staging' : 'test') } environment`}</label>
        </div>}
        <div className='header_tab_icon'>
          <div>
            <TabComponent/>
          </div>
          <div>
            <SSRIcon className="header_icon" paths={gear} onClick={onclickHandler} />
          </div>
        </div>  
      </div>
    </div>)
    }
    {!isHeaderChange && (
    <div className="Container cursor_default" id="editorHeader">
      <div className="TopPanel editor_header_panel cursor_default">
        <div className="cursor_default editor_sub_header">
          <div className='editor_header_left'>
           <Link className="link_nav"  to="/Dashboardpage" key="tab_0">
             <div className="editor_header_label" onClick={headerClickHandler}>
               SITS
             </div>
           </Link>  
           <div className='editor_breadcrumbs'>
             {/* <Link onClick={closeButtonHandler} state={{path: 'searchpage'}} className="link_nav_text editor_breadcrumbs_first_font" to={`/${tab_sel}page`}> */}
             <div onClick={closeButtonHandler} className='link_nav_text editor_breadcrumbs_first_font'> 
              <span className="selectedtab">{tab_sel}</span>
             </div> 
              {/* </Link> */}
              <SSRIcon paths={rightarrow} />
              <span className='editor_breadcrumbs_editor_font'> Editor </span>
            </div>
          </div>  
            {ENV_NAME !== 'PRODUCTION' && <div className="editor_message_label">
          <label className="Pialabel cursor_default">{`Hej, Please be aware that this is a ${ENV_NAME === 'DEVELOPMENT' ? 'test' : (ENV_NAME === 'STAGING' ? 'staging' : 'test') } environment`}</label>
        </div>}
        </div>
        {(sitsContext.autoSaveHeaderChange !== null && sitsContext.autoSaveHeaderChange === true) && <div className='loading_changes'>
          <div className='loading_ball_header'>
        <Loading text="">
          <LoadingBall size='medium' />
        </Loading>
        </div> 
         <h4 className='font_auto_save fontcolor_message_header'>Saving...</h4>
        </div>}
        {(sitsContext.autoSaveHeaderChange !== null && sitsContext.autoSaveHeaderChange === false) && <div className='loading_changes'>
          <div className='checkmark_circle_icon'> <SSRIcon paths={checkmarkcircle}/></div>
          <h4 className='font_auto_save fontcolor_message_header'>Saved</h4>
        </div>}
        {(props.autoSaveErrorFlag === true) && <div className='loading_changes'>
          <div className='checkmark_circle_icon'> <SSRIcon paths={warningtriangle}/></div>
          <h4 className='font_auto_save fontcolor_message_header'>Error saving changes</h4>
        </div>}
        <div className='editor_header_buttons'>
          {/* <Link state={{selectedPageTitle : sitsContext.editorPageStateData.selectedPageTitle,selectedTextData: sitsContext.editorPageStateData.selectedTextData,sourceLocale: sitsContext.editorPageStateData.sourceLocale,targetLocale: sitsContext.editorPageStateData.targetLocale}} to="/Editorpage"><Button className="header_close_button" href="" small text="Close" type="tertiary" onClick={closeButtonHandler}/></Link> */}
          <Button className="header_close_button" href="" small text={lablesAndnames.button_closeButtonText} type="tertiary" onClick={closeButtonHandler}/>
          {props.isReleaseDisabled && <Button className="header_button button_disabled_release" disabled href="" small text={lablesAndnames.button_releaseButtonText} type="primary"/>}
          {!props.isReleaseDisabled && <Link  to="/Editorpage"><Button className="header_button releasebtn" onClick={onReleaseHandler} loading={props.isReleaseloading} href="" small text={lablesAndnames.button_releaseButtonText} type="primary"/></Link>}
        </div>  
      </div>
    </div>)
    }
</div> 
  )
}

export default Header;
